
import axios from 'axios';
import authHeader from '../utils/authHeader';
import authStorage from '../utils/authStorage';

import auth from './auth';
import fetch from './fetch';
import groups from './groups';
import goals from './goals';
import areas from './areas';
import accounts from './accounts';
import badges from './badges';
import links from './links';
import analytics from './analytics';
import rewards from './rewards';
import debug from './debug';
export default { auth, fetch, groups, goals, areas, accounts, badges, links, analytics, rewards, debug };

const urls = {
    'development': 'https://fit.geekyagency.org',
    'production': 'https://fit.geekyagency.org',
    'staging': 'https://fit.geekyagency.org'
};

export const baseAPI = () => {
    const url = urls[process.env.NODE_ENV];
    return url || urls['development'];
};

export const request = (route, data) => {
    const auth = authStorage.get();
    if (auth && auth.token) return axios.post(baseAPI() + route, data, auth ? authHeader() : null);
      else return axios.post(baseAPI() + route, data); 

};
export const storage = authStorage;
