
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';

const AlertDialog = props => {

    const [alert, setAlert] = useState(null);
    const [activeFit, setActiveFit] = useState({'source':''});
    const items = [
        { label: "Apple Health", value: "apple-health" },
        { label: "FitBit", value: "fitbit" }
    ];
    useEffect(() => {
        if (!props.alert) return;
        setAlert(props.alert);
    }, [props.alert]);

    const alertClass = conditional('AlertDialog', style, { visible: alert });

    const finish = () => {
        setAlert(null);
        props.onDone();
    };

    const confirm = async () => {
        await props.alert.onConfirm(activeFit);
        setAlert(null);
    };
    const handleSelectedFitness = e => {
            const id = e.target.value;
            setActiveFit(id);
        
    }

    const alertContent = () => alert ? (
        <div className={style.container}>
            <h3 className={style.title}>{props.alert.title}</h3>
            <p className={style.description}>{props.alert.description}</p>
            <select className={style.boxes + ' i1'} onChange={handleSelectedFitness} value={activeFit}>
                <option key={'none'} value={''}>Select Source</option>
                {items.map(({ label, value }, index) => <option key={index} value={value}>{label}</option>)}
            </select>
            <div className={style.buttonContainer}>
                <div className={style.done} onClick={finish}>{props.alert.doneTitle || 'Done'}</div>
                {props.alert.onConfirm ? <div className={style.done} onClick={confirm}>{props.alert.confirmTitle || 'Confirm'}</div> : null}
            </div>
        </div>
    ) : null;

    return (
        <div className={alertClass}>
            {alertContent()}
        </div>
    );

};

export default AlertDialog;
