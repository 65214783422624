
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';
import api from '../../api';
import publisher from '../../utils/publisher';

const ContactModal = props => {

    const [visible, setVisible] = useState(false);
    const [email, setEmail] = useState(true);

    useEffect(() => {
        if (!props.visible) return;
        setVisible(true);
    }, [props.visible]);

    const next = async () => {
        if (!email.length) return;
        if (!email.includes('@') || !email.includes('.')) return;

        setVisible(false);
        
        try {
            localStorage.setItem('contacted', "true");
            await api.auth.updateContact(email);
        } catch (e) {console.log(e)}
        // if (page >= notifications.length - 1) return dismiss();
        // setPage(page => page += 1);
    };

    const dismiss = () => {
        setVisible(false);
        // setNotifications('initial');
        // props.onDismiss();
    };

    const notificationClass = conditional('NotificationModal', style, { visible });


    return (
        <div className={notificationClass} onClick={dismiss}>
            <div className={style.container} onClick={e => e.stopPropagation()}>
                <div className={style.cards}>
                    <div className={style.card}>
                        <div className={style.photo} style={{  }} />
                        <h3 className={style.title}>{'Welcome to AMP!'}</h3>
                        <p className={style.description}>{'Enter your email address to continue'}</p>
                        <input className={style.input} placeholder="Email address" onChange={e => setEmail(e.target.value)}></input>
                        {/* <div className={style.share} onClick={share}>
                            <span className={'stat-text-c'}>Share This!</span>
                        </div> */}
                    </div>
                </div>
                <div className={style.buttonContainer}>
                    <div className={style.done} onClick={next}>{'Continue'}</div>
                </div>
            </div>
        </div>
    );

};

export default ContactModal;
