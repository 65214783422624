
import React, { useEffect } from 'react';
import style from './style.module.css';
import authStorage from '../../utils/authStorage';

const Admin = props => {

    const fetchAnalytics = async () => {

    };

    useEffect(() => {
        const authData = authStorage.get();
        if (!authData) return alert('You are not signed in. Please sign in to the main app before using this page.');
        fetchAnalytics();
    }, []);

    return (
        <div className={style.Admin}>
            
        </div>
    );

};

export default Admin;
