import { request, storage, mock } from './';

const fetchProfile = async () => {
    const result = await request('/accounts/profile', {});
    return result.data;
};

const sendActiveFit = async (data) => {
    console.log(data)
    const result = await request('/accounts/select', data);
    console.log(JSON.stringify(result.data))
    
    return result.data;
};
const SubmitUserToken = async (data) => {
    console.log(data)
    const result = await request('/accounts/fitbit/sign-in', data);
    console.log(JSON.stringify(result.data))
    return result.data;
};
const DeleteLinkedAcc = async () => {
    const result = await request('/accounts/fitbit/sign-out');
    console.log(JSON.stringify(result.data))
    return result.data;
};
const RemoveFitSource = async () => {
    const result = await request('/accounts/select/reset');
    console.log(JSON.stringify(result.data))
    return result.data;
}
const AddUserInfo = async (data) => {
    const result = await request('/auth/edit-user', {update: data});
    console.log(JSON.stringify(result.data))
    return result.data;
}
const getUserData = async () => {
    const result = await request('/accounts/profile');
    console.log(JSON.stringify(result.data))
    return result.data;
}

export default { fetchProfile, SubmitUserToken, sendActiveFit, DeleteLinkedAcc, RemoveFitSource, AddUserInfo, getUserData };
