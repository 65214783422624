
export default {
    'auth': {
        title: 'Authentication Error',
        description: 'It appears you do not have permission to do this. Try signing in and try again.'
    },
    'incorrect-credentials': {
        title: 'Sign In Error',
        description: 'It appears your email or password is not correct. Try again.'
    },
    'unknown': {
        title: 'Unknown Error',
        description: 'It appears we could not complete this request. Check your connection and try again.'
    }
};
