
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import Navigation from '../Navigation';
import api from '../../api'
import appearance from '../../utils/appearance';
import conditional from '../../utils/conditional';
import authStorage from '../../utils/authStorage';
import ProgressCircle from '../ProgressCircle';
import ShareGoalProgress from '../ShareGoalProgress';
import NotificationModal from '../NotificationModal';
import notificationUtils from './notifications';
import publisher from '../../utils/publisher';
import HistoryModal from '../HistoryModal'
import RewardsBanner from '../RewardsBanner';
import ContactModal from '../ContactModal';
const Home = props => {

    const [showShare, setShowShare] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [showWalkHistory, setShowWalkHistory] = useState(false);
    const [showPointsHistory, setShowPointsHistory] = useState(false);
    const [homeLoaded, setHomeLoaded] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const fetchData = props.fetchData || {};

    const headerGoal = (fetchData.goalProgress || {}).default || {};
    const fetchSteps = fetchData.steps || [];
    const fetchPoints = fetchData.points || [];
    const fetchRank = fetchData.groupRank || [];

    const fetchContact = async () => {
        const prompt = localStorage.getItem('contacted') === 'true';
        if (!prompt) setShowContact(true);
    };

    useEffect(() => { 
        if (!fetchData || !Object.keys(fetchData).length) return;
        setNotifications(notificationUtils.generate(fetchData, props.profile)) 
        setTimeout(() => {
            setHomeLoaded(true);
        }, 6000);
        fetchContact();
    }, [fetchData]);

    const stats = [
        {
            key: 'a',
            name: 'Walk',
            number: ((fetchSteps[0] || {}).value || 0).toLocaleString(),
            metric: 'steps',
            link: () => setShowWalkHistory(true)
        },
        {
            key: 'b',
            name: 'This Quarter',
            number: (fetchData.quarterPoints || 0).toLocaleString(),
            metric: 'points',
            link: () => props.history.push('/badges')
        },
        {
            key: 'c',
            name: 'Daily Points',
            number: ((fetchPoints[0] || {}).value || 0).toLocaleString(),
            metric: 'earned',
            link: () => setShowPointsHistory(true)
        },
        {
            key: 'd',
            name: 'Rank',
            number: fetchRank.length ? '#' + ((fetchRank[0] || {}).rank || 0).toLocaleString() : 'N/A',
            metric: 'in group',
            link: () => props.history.push('/groups')
        }
    ];
    // const waitForThis = async _ => {
    //     const result = await api.accounts.DeleteLinkedAcc()
    //     const resultSource = await api.accounts.RemoveFitSource()
    //     props.resync();
    // }
    // waitForThis();

    const shareHeaderGoal = async () => {
        const percentage = Math.round((headerGoal.percentage || 0) * 100);

        const shareData = {
            type: percentage < 100 ? 'goals-behind' : 'goals-ahead',
            goalType: 'default'
        };

        const link = await api.links.add(shareData);
        publisher.publish('shareOptions', link);
    };

    const box = stat => (
        <div className={style.box + ' stat-background-' + stat.key + ' ' + style[stat.key]} onClick={() => stat.link ? stat.link() : console.log('no-link')}>
            <div className={style.label + ' stat-subtext-' + stat.key}>{stat.name}</div>
            <div className={style.stat}>
                <div className={style.number + ' stat-text-' + stat.key}>{stat.number}</div>
                <div className={style.metric + ' stat-subtext-' + stat.key}>{stat.metric}</div>
            </div>
        </div>
    );

    const reward = stat => (
        <div className={style.box + ' stat-background-' + stat.key + ' ' + style[stat.key]} onClick={() => stat.link ? stat.link() : console.log('no-link')}>
            <div className={style.label + ' stat-subtext-' + stat.key}>{stat.name}</div>
            <div className={style.stat}>
                <div className={style.number + ' stat-text-' + stat.key}>{stat.number}</div>
                <div className={style.metric + ' stat-subtext-' + stat.key}>{stat.metric}</div>
            </div>
        </div>
    );

    const tipsClass = conditional('tips', style, { visible: props.fetchData !== null })
    const statsClass = conditional('stats', style, { visible: props.fetchData !== null })
    const wheelTextClass = conditional('wheelText', style, { visible: props.fetchData !== null })
    const starClass = conditional('giftBox', style, { visible: props.fetchData !== null }); // props.fetchData !== null

    const percentage = Math.round((headerGoal.percentage || 0) * 100);
    const percentTitle = percentage > 50 ? 'Great work, ' + props.profile.firstName + '!' : 'Keep going, ' + props.profile.firstName + '!'
    const percentTip = percentage > 50 ? 'We\'re blown away!' : 'There\'s still time, keep going!'
    const auth = authStorage.get();
    return (
        <Navigation hideNavigationBar>
            <div className={style.content}>
                <div className={style.infoBar}>
                    <div className={style.description + ' h3'}>{(new Date()).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}</div>
                    <div className={style.lightSwitch + ' stat-background-b'} onClick={() => appearance.toggleTheme()}><span className="material-icons stat-text-b">wb_sunny</span></div>
                </div>
                <div className={style.highlightWheel}>
                    <div className={style.wheel}>
                        <ProgressCircle percentage={Math.min((headerGoal.percentage || 0), 1.0)} />
                        <div className={wheelTextClass + ' wheel-text'}>{percentage}%</div>
                        <div className={starClass} onClick={() => props.showRewards()}></div>
                    </div>
                    <div className={tipsClass}>
                        <div className={style.title + ' h1'}>{percentTitle}</div>
                        <div className={style.subtitle + ' h2'} onClick={() => {}}>Right now, you're at {percentage}% of your {headerGoal.time} {headerGoal.type} goal. {percentTip}</div>
                        <div className={style.share + ' stat-background-c'} onClick={shareHeaderGoal}><span className={'stat-text-c'}>Share It</span></div>
                    </div>
                </div>
                <RewardsBanner unclaimed={props.unclaimed} onSelect={() => props.showRewards()} />
                <div className={statsClass}>
                    {stats.map(stat => box(stat))}
                </div>
            </div>
            {/* <ShareGoalProgress visible={showShare} onFinish={() => setShowShare(false)} navigationTitle={'Share Progress'} /> */}
            <NotificationModal notifications={notifications} />
            <ContactModal visible={showContact} />
            <ContactModal />
            
            {/* TODO: these cause performance issues, look into it */}
            {homeLoaded ? (
                <>
                    <HistoryModal visible={showWalkHistory} steps={fetchSteps} title={'Walk History'} kind={'Steps Walked'} onFinish={()=>{setShowWalkHistory(false);props.resync();}} />
                    <HistoryModal visible={showPointsHistory} steps={fetchPoints} title={'Points History'} kind={'Points Earned'} onFinish={()=>{setShowPointsHistory(false);props.resync();}} />
                </>
            ) : null}
        </Navigation>
    );

};

export default Home;
