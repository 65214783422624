
import axios from 'axios';

// TODO: yes there will be another kind of goal thats not personal

const log = async event => {
    const result = await axios.post('https://microservices.geekydevelopment.com/debug', { event });
    return result.data;
};

export default { log };
