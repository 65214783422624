
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import api from '../../api';
import AlertDialog from '../AlertSelect';
const RequestItem = props => {

    const [alert, setAlert] = useState(null);
    const itemData = {
        title: '',
        description: null,
        stat: '',
        photo: null,
        rank: '',
        metric: '',
        appearance: 'g1',
    };


    if (props.itemType === 'requests') {
        itemData.title = props.data.firstName + " " + props.data.lastName;
        itemData.description = 'Approve';
        itemData.appearance = props.data.index < 4 ? 'g' + (props.data.index + 1) : 'g4';
    }
    if (props.itemType === 'group') {
        console.log(props)
        itemData.title = props.data.name
        itemData.description = 'Request';
        itemData.appearance = props.data.index < 4 ? 'g' + (props.data.index + 1) : 'g4';
    }

    const addUserToGroup = async () => {
        //submit user  
        try {
            await api.groups.acceptUserToGroup({'group':`${props.data.groupId}`,'user':`${props.data._id}`})
            props.refresh()
        } catch (e) {
            console.log(e)
        }

    };

    const handleJoinGroup = async (groupId) =>{
        try {
            await api.groups.joinGroup({ 'group': `${groupId}` });
            props.refresh.onFinish()
        } catch (e) {
            console.log(e)

        }
    }
    return (
        <div>
            {props.itemType === 'group' ? (
            <div className={style.Item + ' ' + style[itemData.appearance]} onClick={() => setAlert({
                title: 'Request to join this group?',
                desc: 'yes',
                doneTitle: 'Cancel',
                confirmTitle: 'Add',
                confirmColor: 'rgb(255, 49, 42)',
                onConfirm: async () => handleJoinGroup(props.data._id)
            })}>
                <div className={style.info}>
                    <div className={style.title}>{itemData.title}</div>
                    <div className={style.description}>{itemData.description}</div>
                </div>

            </div>
                )
            :null}
            {props.itemType === 'requests' ? (
        <div className={style.Item + ' ' + style[itemData.appearance]} onClick={() => setAlert({
                        title: 'Add this user?',
                        desc: 'ok',
                        doneTitle: 'Cancel',
                        confirmTitle: 'Add',
                        confirmColor: 'rgb(255, 49, 42)',
                        onConfirm: async () => addUserToGroup()
                    })}>
                <div className={style.info}>
                    <div className={style.title}>{itemData.title}</div>
                    <div className={style.description}>{itemData.description}</div>
                </div>
        </div>):null}
        <AlertDialog alert={alert} onDone={() => setAlert(null)} />
        </div>
    );

};

export default RequestItem;