
import React from 'react';
import style from './style.module.css';
const GoalItem = props => {

    const itemData = {
        title: '',
        description: null,
        stat: '',
        photo: null,
        rank: '',
        metric: '',
        appearance: 'g1',
    };
    const caps = string => string.charAt(0).toUpperCase() + string.slice(1);

    if (props.itemType === 'goals') {
        itemData.title = props.data.goal.toLocaleString() + ' ' + caps(props.data.time) + ' ' + caps(props.data.type);
        itemData.description = props.data.percentage > .5 ? 'Way to go!' : 'You can do it!';
        itemData.stat = Math.round(props.data.percentage * 100) + '%';
        itemData.rank = Math.max(props.data.goal - props.data.progress, 0).toLocaleString();
        itemData.metric = props.data.type + ' to go';
        itemData.appearance = props.data.index < 4 ? 'g' + (props.data.index + 1) : 'g4';
    }

    if (props.itemType === 'leaderboards') {
        const rank = '#' + (props.data.index + 1);
        const names = ['Gold', 'Silver', 'Bronze'];

        itemData.stat = rank;
        itemData.title = props.data.firstName + ' ' + props.data.lastName;
        itemData.description = names[props.data.index] || null;
        itemData.rank = props.data.quarterPoints.toLocaleString();
        itemData.metric = 'points';
        itemData.photo = props.data.secondaryInfo.photo;
        itemData.appearance = props.data.index < 4 ? 'g' + (props.data.index + 1) : 'g4';
    }
    if (props.itemType === 'analytics') {
        const rank = '#' + (props.data.index + 1);
        const names = ['Gold', 'Silver', 'Bronze'];

        itemData.stat = rank;
        itemData.title = props.data.firstName + ' ' + props.data.lastName;
        itemData.description = names[props.data.index] || null;
        itemData.rank = props.data.aggregate.points.toLocaleString();
        itemData.metric = 'points';
        itemData.appearance = props.data.index < 4 ? 'g' + (props.data.index + 1) : 'g4';
    }

    if (props.itemType === 'globals') {
        const rank = '#' + (props.data.index + 1);

        itemData.stat = rank;
        itemData.title = props.data.name;
        itemData.rank = props.data.quarterPoints.toLocaleString();
        itemData.metric = 'points';
        itemData.appearance = props.data.index < 4 ? 'g' + (props.data.index + 1) : 'g4';
    }

    if (props.itemType === 'option') {
        itemData.title = props.data.name;
        itemData.description = props.data.description;
        itemData.appearance = props.data.index < 4 ? 'g' + (props.data.index + 1) : 'g4';
    }


    return (
        <div>
        <div className={style.Item + ' ' + style[itemData.appearance]} onClick={props.onSelect}>
            <div className={style.stat}>{itemData.stat}</div>
            {itemData.photo ? (
                <div className={style.photo} style={{ backgroundImage: 'url(' + itemData.photo + ')' }}>
                    
                </div>
            ) : null}
            <div className={style.info}>
                <div className={style.title}>{itemData.title}</div>
                <div className={style.description}>{itemData.description}</div>
            </div>
            <div className={style.rank}>
                <div className={style.value}>{itemData.rank}</div>
                <div className={style.metric}>{itemData.metric}</div>
            </div>
        </div>
        </div>
    );

};

export default GoalItem;
