
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import { BrowserRouter, Route, withRouter, Redirect } from 'react-router-dom';

import TabBar from '../TabBar';
import AlertDialog from '../AlertDialog';
import AlertSelect from '../AlertSelect';
import AlertFitBit from '../AlertFitBit';
import ShareDialog from '../ShareDialog';

import Home from '../Home';
import Goals from '../Goals';
import Groups from '../Groups';
import Badges from '../Badges';
import Settings from '../Settings';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import SignOut from '../SignOut';
import SignInVerify from '../SignInVerify';
import LinkPage from '../LinkPage';
import Admin from '../Admin';

import platform from 'geeky-platform';
import appearance from '../../utils/appearance';
import conditional from '../../utils/conditional';

import sync from '../../api/sync';
import alerts from '../../api/alerts';
import api from '../../api';
import axios from 'axios';
import publisher from '../../utils/publisher';
import authStorage from '../../utils/authStorage';
import Profile from '../Profile';
import SecondaryInfoModal from '../SecondaryInfoModal';
import Rewards from '../Rewards';
import RewardsBanner from '../RewardsBanner';

const routes = [
    { path: '/', component: Home },
    { path: '/goals', component: Goals },
    { path: '/groups', component: Groups },
    { path: '/badges', component: Badges },
    { path: '/settings', component: Profile }
    // { path: '/invite/:code', component: InvitePage }
];

const walls = {
    '/sign-in': SignIn,
    '/sign-up': SignUp,
    '/sign-out': SignOut,
    '/verify': SignInVerify,
    '/l': LinkPage,
    '/admin': Admin
};

let lastDate = null;
let focused = true;

const Routes = props => {

    const [fetchData, setFetchData] = useState(null);
    const [alertSelected, setAlertSelected] = useState(null);
    const [alertFitBit, setAlertFitBit] = useState(null);
    const [alertDialog, setAlertDialog] = useState(null);
    const [profileUser, setProfileUser] = useState(null);
    const [userInfoPrompts, setUserInfoPrompts] = useState(null);
    
    const fetchProfileUser = async () => {
        const result = await api.accounts.fetchProfile();
        setProfileUser(result);
        return result;
    };

    const onActionRequired = (selected) => {
        switch (selected) {
            case 'none-selected':
                console.log("Show Picker Dialog")
                setUserInfoPrompts(['source']);
                // setAlertDialog({
                //     title: 'How do you want to track your steps?',
                //     desc: 'ok',
                //     doneTitle: 'Cancel',
                //     confirmTitle: 'Done',
                //     confirmColor: 'rgb(255, 49, 42)',
                //     onConfirm: (selected) => {
                //         const doThis = async _ => {
                //             await api.accounts.sendActiveFit({ 'source': selected })
                //             setAlertDialog(null);
                //             resync();
                //         }
                //         doThis()
                //     }
                // })
                break;
            case 'sign-in':
                console.log("Show Fitbit Sign In")
                setAlertFitBit({
                    title: 'Please Sign Into FitBit',
                    desc: 'ok',
                    doneTitle: 'Cancel',
                    confirmTitle: 'Done',
                    onConfirm: (accessToken) => {
                        setAlertFitBit(null);
                        resync(true);
                        // const doThis = async _ => {
                        //     await api.accounts.SubmitUserToken({ 'token': `${accessToken}` })
                        //     setAlertFitBit(null);
                        //     resync(true);
                        // }
                        // doThis()
                    }
                })
                break;
            // case 'apple-sign-in':

            default:

        }
    }

    const promptUserData = async (manuals = []) => {
        // get user data first and only ask if missing
        const profile = await fetchProfileUser();
        
        const promptIds = manuals;

        if (!profile.goals.length) promptIds.push('goals');
        if (!profile.secondaryInfo.area) promptIds.push('area');
        if (!profile.secondaryInfo.birthday) promptIds.push('birthday');
        if (!profile.secondaryInfo.weightKilograms) promptIds.push('weight');
        if (!profile.secondaryInfo.heightCentimeters) promptIds.push('height');
        if (!profile.secondaryInfo.photo) promptIds.push('photo');

        if (promptIds.length) setUserInfoPrompts(promptIds);
    };

    const resync = (promptOnFinish = false) => {
        if (!lastDate) {
            console.log('No sync date found');
            return;
        }
        console.log('sync date', lastDate);
        // api.debug.log('test event');

        fetchRewards();

        sync.update(lastDate, setFetchData, onActionRequired, () => {
            fetchRewards();
            if (promptOnFinish) promptUserData();
        });
    };

    const fetchRewards = async () => {
        // api.debug.log('test event 1');

        const results = await Promise.all([api.rewards.fetch(), api.rewards.fetchAvailable()]);
        // console.log('res', results);

        // const groups = await api.rewards.fetch();
        // api.debug.log('test event 2');
        // const available = await api.rewards.fetchAvailable();
        // api.debug.log('test event 3');
        props.onRewards({ groups: results[0], available: results[1] });
    };

    useEffect(() => {
        const search = props.history.location.search || '';

        if (search.includes('?date=')) {
            lastDate = search.split('?date=')[1];
            console.log('device date selected');
        } else {
            console.log('js date selected');
            // idk about this man. were gonna use device time when available until this is more reliable

            const date = new Date();
            const offset = date.getTimezoneOffset() / 60;
            date.setHours(date.getHours() - offset, date.getMinutes(), date.getSeconds(), 0);

            const tz = offset.toString().padStart(2, '0') + '00';
            lastDate = date.toISOString().split('Z').join('-' + tz);
        }
        resync();
        fetchProfileUser();
        subscribeToUpdates();
        fetchRewards();

        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                if (!focused) resync();
                focused = true;
            } else {
                focused = false;
            }
        });
        // android is bullshit so it needs a separate function smh
        window.resyncAndroidApp = refetchData;
    }, []);

    const refetchData = async () => {
        const fetchedData = await api.fetch.refetch(lastDate);
        if (fetchedData) setFetchData(fetchedData);
    };

    const renderedRoutes = routes.map(route => {
        const Component = route.component;
        const routeContent = conditional('routeContent', style, { visible: props.history.location.pathname === route.path });

        return (
            <div className={style.fakeAssRouteHolder}>
                <Route exact key={route.path} path={route.path} render={() => <div />} />
                <div className={routeContent}>
                    <Component
                        key={route.path}
                        path={route.path}
                        error={props.error}
                        loading={props.loading}
                        profile={props.profile}
                        profileUser={profileUser}
                        unclaimed={props.unclaimed}
                        updateProfile={fetchProfileUser} // {/* delete this if ur doing local data model */}
                        fetchData={fetchData}
                        redirect={props.redirect}
                        showRewards={props.showRewards}
                        resync={resync}
                        refetchRewards={fetchRewards}
                        history={props.history}
                        auth={props.auth}
                        promptUserInfo={settings => setUserInfoPrompts(settings)} />
                </div>
            </div>
        );
    });

    const dismissSecondaryInfo = editedIds => {
        setUserInfoPrompts(null);
        fetchProfileUser();
        
        if (editedIds.includes('source')) resync(true);
    };

    const subscribeToUpdates = () => {
        const token = authStorage.get().token;
    
        const socket = new WebSocket('wss://fit.geekyagency.org/chat');
        socket.onopen = () => sendData(socket, { type: 'subscribe', token });
        socket.onmessage = message => {
            const data = recieveData(message.data);
            if (data.type === 'refetch') resync();
        };
        socket.onerror = e => {
            console.log('error')
        }
    };

    const sendData = (ws, obj) => {
        try {
            const data = JSON.stringify(obj);
            ws.send(data);
            console.log('senty')
        } catch (e) {
            console.log('error')
        }
    };
    
    const recieveData = data => {
        try {
            return JSON.parse(data);
        } catch (e) {
            return null;
        }
    };

    return (
        <div className={style.Routes}>
            <div className={style.routeContainer}>
                {renderedRoutes}
            </div>
            <AlertDialog alert={alertDialog} onDone={() => {
                setAlertDialog(null);
                resync();
            }} />
            <AlertFitBit alert={alertFitBit} onDone={() => {
                api.accounts.RemoveFitSource();
                setAlertFitBit(null);
                resync();
            }} />
            <SecondaryInfoModal settings={userInfoPrompts} onDismiss={dismissSecondaryInfo} />
        </div>
    );

};

const Container = withRouter(props => {

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [auth, setAuth] = useState(null);
    const [shareData, setShareData] = useState(null);
    const [showRewards, setShowRewards] = useState(null);
    const [rewards, setRewards] = useState(null);

    useEffect(() => {
        platform.setup();
        appearance.configure();
        publisher.subscribe('shareOptions', data => {
            setShareData(data);
        });
    }, []);

    const handleError = e => {
        const code = e.code || 'unknown';
        const alert = alerts[code] || alerts['unknown'];
        setAlert(alert);
    };

    const waitTest = () => new Promise(resolve => setTimeout(() => {
        resolve();
    }, 5000));

    const fetchRewards = async () => {
        const groups = await api.rewards.fetch();
        const available = await api.rewards.fetchAvailable();
        console.log(groups, available)
        // await waitTest();
        setRewards({ groups, available });
    };

    const Wall = props.location.pathname.includes('/l/') ? walls['/l'] : walls[props.location.pathname];

    if (Wall) return (
        <div className={style.wallContainer + ' background'}>
            <Wall
                history={props.history}
                error={handleError}
                path={props.location.pathname}
                onAuth={result => setAuth(result)} />
            <AlertDialog alert={alert} onDone={() => setAlert(null)} />
        </div>
    );

    const authData = auth || props.authData;
    if (!authData) return <Redirect to="/sign-up" />;

    const profile = (authData || {}).user || {};
    const unclaimed = ((rewards || {}).groups || []).filter(group => !group.claimed).length;
    console.log(unclaimed)

    return (
        <div className={style.container + ' background'}>
            <div className={style.content + ' background'}>
                <Routes
                    loading={setLoading}
                    error={handleError}
                    profile={profile}
                    history={props.history}
                    auth={authData}
                    unclaimed={unclaimed}
                    onRewards={setRewards}
                    showRewards={() => setShowRewards(true)} />
            </div>
            <TabBar history={props.history} />
            <AlertDialog alert={alert} onDone={() => setAlert(null)} />
            <ShareDialog shareData={shareData} />
            <Rewards rewards={(rewards || {}).groups || []} available={(rewards || {}).available || []} visible={showRewards} onReload={fetchRewards} onFinish={() => setShowRewards(false)} />
        </div>
    );

});

const App = props => (
    <div className={style.App}>
        <BrowserRouter>
            <Container authData={props.authData} />
        </BrowserRouter>
    </div>
);

export default App;
