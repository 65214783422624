
import React from 'react';
import style from './style.module.css';

const AddNewFooter = props => {

    return (
        <div className={style.Item} onClick={props.onSelect}>
            <div className={style.title}>Add New</div>
        </div>
    );

};

export default AddNewFooter;
