

// k the old system was total shit so were gonna do sort of event lifecycle here

import { request } from './';
import platform from 'geeky-platform';
import authStorage from '../utils/authStorage';
import mockSteps from './mockSteps';

const update = async (date, setData, setActionRequired, onFinish) => {
    // first thing is fetch. if the user has recntly syncd its gonna be hella fast
    try {
        const fetchResult = await request('/fetch', { date });
        setData(fetchResult.data);
    } catch (e) {} // nbd move on

    // next we gonna check to see if theres an activity source selected and make sure its logged in if its fitbit
    // this was causin huge issues if the auth token expired or somethin

    try {
        const checkResult = await request('/accounts/check', {});
        console.log(checkResult.data)
        // if the user hasnt selected an activity source we gotta make them do that
        if (checkResult.data.status === 'not-selected') {
            setActionRequired('none-selected')
            return;
        }

        // if the user has an activity source selected but not signed in make them do that too
        if (checkResult.data.status === 'not-signed-in') {
            setActionRequired('sign-in');
            return;
        }

        // if we got the data from fitbit we can update the app
        if (checkResult.data.source === 'fitbit' && checkResult.data.data) {
            setData(checkResult.data.data);
            return;
        }

        // prob no fitbit data came back so need to authenticate
        if (checkResult.data.source === 'fitbit') {
            setActionRequired('sign-in');

            const auth = authStorage.get();

            if (!auth || !auth.token) {
                console.log('no auth token, this is a dev issue');
                return;
            }

            const data = { token: auth.token };
            platform.request({ id: 'authManager', data });

            onFinish();

            return;
        }

        // now its the devices responsibility to get the steps
        if (checkResult.data.status === 'ready') {
            const auth = authStorage.get();

            if (!auth || !auth.token) {
                console.log('no auth token, this is a dev issue');
                return;
            }

            const data = {
                status: 'ready',
                source: checkResult.data.source,
                token: auth.token
            };

            if (process.env.REACT_APP_FIT_SOURCE) {
                const syncResult = await request('/sync', { steps: { [date]: mockSteps }, distance: {} });
                setData(syncResult.data);
            }

            // once the data comes in from the device we can handle that here
            platform.subscribeJS({ id: 'fitnessManager' }, data => setData(data));

            // what we gotta do is request an update now
            platform.request({ id: 'fitnessManager', data });

            onFinish();
        }

        // also if any of these issues happen u gotta handle them and then run this process over again
    } catch (e) {}
};

export default { update };
