
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import platform from 'geeky-platform';
import api from '../../api';

const baseLink = 'https://activemarion.app/l/';

const Rewards = props => {

    const [visible, setVisible] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [redeemVisible, setRedeemVisible] = useState(false);
    const [selectedReward, setSelectedReward] = useState(null);
    const [claiming, setClaiming] = useState(false);
    const [redeeming, setRedeeming] = useState(false);
    const [countdown, setCountdown] = useState(15);

    useEffect(() => {
        if (!props.shareData) return;
        setVisible(true);
    }, [props.shareData]);

    const share = service => {
        const link = props.shareData || {};

        let message = '';
    
        if (link.rank) {
            message = `I'm #${link.rank.rank} in ${link.rank.name} on Active Marion Project!\n${baseLink}${link.code}`;
        }
    
        if (link.goal) {
            console.log('goal')
            const percent = Math.round(link.goal.percentage * 100);
            message = `I reached my ${link.goal.time} ${link.goal.type} goal by ${percent}%!\n${baseLink}${link.code}`;
        }
    
        if (link.badge) {
            message = `I earned the ${link.badge.name} badge!\n${baseLink}${link.code}`;
        }

        platform.request({ id: 'shareApp', data: { service, message }});
    };

    const claim = async () => {
        setClaiming(true);

        console.log(selectedReward.group);
        await api.rewards.claim(selectedReward.group._id, selectedReward._id);
        await props.onReload();

        setDialogVisible(false);
        setTimeout(() => {
            setSelectedReward(null);
            setClaiming(false);
        }, 600);
    };

    const waitTest = () => new Promise(resolve => setTimeout(() => {
        resolve();
    }, 15000));

    const redeem = async () => {
        setClaiming(true);

        await api.rewards.redeem(selectedReward._id);

        setRedeeming(true);
        setCountdown(15);
        await props.onReload();

        const timer = setInterval(() => {
            setCountdown(value => value - 1);
        }, 1000);

        await waitTest();

        clearTimeout(timer);

        setRedeemVisible(false);
        setTimeout(() => {
            setSelectedReward(null);
            setClaiming(false);
            setRedeeming(false);
        }, 600);
    };

    const rewardsClass = conditional('Rewards', style, { visible: props.visible });
    const actionsClass = conditional('confirmActions', style, { disabled: claiming });
    const redeemActionsClass = conditional('redeemActions', style, { disabled: claiming });

    const availableCard = data => 
        <div className={style.availableCard} onClick={() => {
            if (data.status !== 'eligible') return;
            setSelectedReward(data);
            setDialogVisible(true);
            }}>
            <div className={style.top}>
                <div className={style.lock} style={{ backgroundColor: data.status === 'eligible' ? 'rgba(103, 202, 163, 1.0)' : 'rgba(234, 171, 120, 1.0)' }}/>
                <div className={style.text + ' h3'}>{data.status === 'eligible' ? 'Eligible' : 'Keep Going'}</div>
            </div>
            <div className={style.spacer} />
            <div className={style.icon} style={{ opacity: data.status === 'eligible' ? '1.0' : '0.5', backgroundImage: 'url(\'' + data.secondaryInfo.thumbnail + '\')' }} />
            <div className={style.company + ' h2'} style={{ opacity: data.status === 'eligible' ? '1.0' : '0.5' }}>{data.secondaryInfo.company || 'AMP Rewards'}</div>
            <div className={style.title + ' h15'} style={{ opacity: data.status === 'eligible' ? '1.0' : '0.5' }}>{data.name}</div>
        </div>;

    const earnedCard = data => 
        <div className={style.availableCard} onClick={() => {setSelectedReward(data);setRedeemVisible(true)}}>
            <div className={style.top}>
                {/* {JSON.stringify(data)} */}
                <div className={style.lock} style={{ backgroundImage: 'url(' +  + ')', backgroundColor: data.redeemed ? 'rgba(229, 71, 82, 1.0)' : 'rgba(103, 202, 163, 1.0)' }}/>
                <div className={style.text + ' h3'}>{data.redeemed ? 'Redeemed' : 'Available'}</div>
            </div>
            <div className={style.spacer} />
            <div className={style.icon} style={{ opacity: '1.0', backgroundImage: 'url(\'' + data.choice.secondaryInfo.thumbnail + '\')' }} />
            <div className={style.company + ' h2'} style={{ opacity: '1.0' }}>{data.choice.secondaryInfo.company || 'AMP Rewards'}</div>
            <div className={style.title + ' h15'} style={{ opacity: '1.0' }}>{data.choice.name}</div>
        </div>;

    const progressCard = data => 
        <div className={style.progressCard}>
            {/* <div className={style.top}>
                <div className={style.lock} style={{ backgroundImage: 'url(' +  + ')', backgroundColor: data.redeemed ? 'rgba(229, 71, 82, 1.0)' : 'rgba(103, 202, 163, 1.0)' }}/>
                <div className={style.text + ' h3'}>{data.redeemed ? 'Redeemed' : 'Available'}</div>
            </div>
            <div className={style.spacer} /> */}
            <div className={style.icon} style={{ opacity: '1.0', backgroundImage: 'url(\'' + data.secondaryInfo.thumbnail + '\')' }} />
            <div className={style.company + ' h2'} style={{ opacity: '1.0' }}>{data.secondaryInfo.company || 'AMP Rewards'}</div>
            <div className={style.title + ' h15'} style={{ opacity: '1.0' }}>{data.name}</div>
        </div>;

    const section = data => (
        <div className={style.section}>
            <div className={style.header}>
                <div className={style.sectionSubtitle + ' h3'}>{data.name}</div>
            </div>
            <div className={style.content}>
                {(data.choices || []).map(choice => ({ ...choice, group: data })).map(availableCard)}
                <div className={style.cardSpacer} />
            </div>
        </div>
    );

    const usedCount = ((selectedReward || {}).viewDates || []).length;

    const confirmClass = conditional('confirmDialog', style, { visible: dialogVisible });
    const redeemClass = conditional('redeemDialog', style, { visible: redeemVisible });

    const redeemContainer = conditional('redeemContainer', style, { visible: !redeeming });
    const redeemingContainer = conditional('redeemingContainer', style, { visible: redeeming });
    const redeemMessage = conditional('redeemMessage', style, { alert: usedCount > 0 });
    const redeemCodeClass = conditional('redeemCode', style, { animating: redeeming });

    const progressRewards = (((selectedReward || {}).group || {}).choices || []).filter(choice => choice._id !== (selectedReward || {})._id);

    return (
        <div className={rewardsClass} onClick={() => props.onFinish()}>
            
            <div className={style.container} onClick={e => e.stopPropagation()}>
                {(props.available && props.available.length) ? (
                    <div className={style.section}>
                        <div className={style.header}>
                            <div className={style.sectionSubtitle + ' h3'}>Claimed Rewards</div>
                        </div>
                        <div className={style.content}>
                            {props.available.map(choice => ({ ...choice, group: {} })).map(earnedCard)}
                            <div className={style.cardSpacer} />
                        </div>
                    </div>
                ) : null}
                
                {(props.rewards || []).map(section)}

                {/* Right on, you earned {data.name} from {data.company}! If you cash out now, you'll miss out on the other {group.name} rewards. */}
                {/* [Claim It] [Keep Going] */}
                {/* full screen maybe blur the bg */}
                {/* <div className={style.section}>
                    <div className={style.header}>
                        <div className={style.sectionSubtitle + ' h2'}>Available</div>
                    </div>
                    <div className={style.content}>
                        {availableCard({title: 'Free Pourover', company: 'Coffeebar', eligible: true})}
                        {availableCard({title: 'Juice on the House', company: 'Wonder Press', eligible: false})}
                        {availableCard({title: 'Free Pourover', company: 'Coffeebar', eligible: true})}
                        {availableCard({title: 'Juice on the House', company: 'Wonder Press', eligible: false})}
                        {availableCard({title: 'Free Pourover', company: 'Coffeebar', eligible: true})}
                        {availableCard({title: 'Juice on the House', company: 'Wonder Press', eligible: false})}
                        {availableCard({title: 'Free Pourover', company: 'Coffeebar', eligible: true})}
                        {availableCard({title: 'Juice on the House', company: 'Wonder Press', eligible: false})}
                        <div className={style.cardSpacer} />
                    </div>
                </div> */}

                {/* <div className={style.header}>
                    <div className={style.sectionTitle + ' h1'}>Claimed{JSON.stringify(props.rewards)}</div>
                    <div className={style.sectionSubtitle + ' h2'}>{'Ready to cash out'}</div>
                </div> */}
                {/* <div className={style.social + ' ' + style.facebook} onClick={() => share('facebook')} /> */}
                {/* <div className={style.social + ' ' + style.message} onClick={() => share('message')} /> */}
                {/* <div className={style.social + ' ' + style.instagram} onClick={() => share('instagram')} /> */}
                {/* <div className={style.social + ' ' + style.twitter} onClick={() => share('twitter')} /> */}
            </div>
            <div className={style.nav} onClick={e => e.stopPropagation()}>
                {/* <div className={style.navIcon} /> */}
                <div className={style.navTitle + ' h1'}>Rewards</div>
                <div className={style.navClose} onClick={() => props.onFinish()} />
            </div>
            <div className={confirmClass} onClick={e => e.stopPropagation()}>
                <div className={style.confirmContainer}>
                    <div className={style.confirmIcon} style={{ backgroundImage: 'url(\'' + ((selectedReward || {}).secondaryInfo || {}).thumbnail + '\')' }} />
                    <div className={style.confirmMessage + ' h2'}>Right on, you earned {(selectedReward || {}).name} from {((selectedReward || {}).secondaryInfo || {}).company}!{progressRewards.length ? ' If you cash out now, you won\'t be able to earn these rewards.' : ' Claim your reward for your hard work!'}</div>
                    <div className={style.confirmPreview} style={{ display: !progressRewards.length ? 'none' : 'flex' }}>
                        {/* preview of next */}
                        {/* <div className={style.confirmPreviewMessage}>Here's what you're missing out on if you cash out now</div> */}
                        {/* {JSON.stringify(progressRewards)} */}
                        {progressRewards.map(progressCard)}
                        <div className={style.cardSpacer} />
                    </div>
                    <div className={actionsClass}>
                        <div className={'cancel'} onClick={() => setDialogVisible(false)}>{!progressRewards.length ? 'Cancel' : 'Keep Going'}</div>
                        <div className={'button'} onClick={claim}>Claim</div>
                    </div>
                </div>
            </div>

            <div className={redeemClass} onClick={e => e.stopPropagation()}>
                <div className={redeemContainer}>
                    <div className={style.redeemIcon} style={{ backgroundImage: 'url(\'' + (((selectedReward || {}).choice || {}).secondaryInfo || {}).thumbnail + '\')' }} />
                    <div className={style.redeemMessage + ' h2'}>Show someone at {(((selectedReward || {}).choice || {}).secondaryInfo || {}).company} your phone, then tap the green button. Watch out, the reward will be marked as redeemed permanently!</div>
                    <div className={redeemActionsClass}>
                        <div className={'cancel'} onClick={() => setRedeemVisible(false)}>Not Yet</div>
                        <div className={'button'} onClick={redeem}>I'm Ready!</div>
                    </div>
                </div>
                <div className={redeemingContainer}>
                    <div className={style.redeemIcon} style={{ backgroundImage: 'url(\'' + (((selectedReward || {}).choice || {}).secondaryInfo || {}).thumbnail + '\')' }} />
                    <div className={redeemCodeClass + ' h1'}>{((selectedReward || {}) || {}).code}</div>
                    <div className={redeemMessage + ' h2'}>{usedCount > 0 ? 'This code was attempted ' + (usedCount - 0) + ' times before.' : 'This code has not been redeemed before.'}</div>
                    <div className={style.redeemCounter + ' h2'}>{countdown}s</div>
                </div>
            </div>
        </div>
    );

};

export default Rewards;
