
import { request } from './';

const refetch = async date => {
    try {
        const fetchResult = await request('/fetch', { date });
        return fetchResult.data;
    } catch (e) {
        return null;
    }
};

export default { refetch };
