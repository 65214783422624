
import { request } from './';

const fetch = async code => {
    const result = await request('/links/fetch', { code });
    return result.data;
};

const add = async shareData => {
    const result = await request('/links/add', { shareData });
    return result.data;
};

export default { fetch, add };
