
import React, { useRef, useState, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';

const ProgressCircle = props => {

    const wheelFill = useRef();

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (props.percentage !== null) setVisible(true);
        const progress = (props.percentage * 100) + ', 100';
        wheelFill.current.style.strokeDasharray = progress;
    }, [props.percentage]);

    const progressClass = conditional('ProgressCircle', style, { visible });

    return (
        <div className={progressClass}>
            <svg viewBox="0 0 36 36" className={style.container}>
                <path className={style.circleOutline + ' wheel-bg'} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                <path className={style.circleProgress + ' wheel-fill'} ref={wheelFill} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
            </svg>
        </div>
    );

};

export default ProgressCircle;
