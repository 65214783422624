
import React, { useEffect } from 'react';
import style from './style.module.css';
import useSmoothState from '../../utils/useSmoothState';
import Modal from '../Modal';

const BadgeShow = props => {

    const [badge, setBadge] = useSmoothState(null, 0.5);

    useEffect(() => {
        if (!props.badge) {
            setBadge('initial');
            return;
        }

        setBadge(props.badge);
    }, [props.badge]);

    const badgeInfo = badge || {};

    const additionalInfo = () => badgeInfo.earned ? (
        <div className={style.info}>
            <div className={style.label}>Congratulations!</div>
            <div className={style.description}>{(badgeInfo.secondaryInfo || {}).completion}</div>
        </div>
    ) : (
        <div className={style.info}>
            <div className={style.label}>How To Earn</div>
            <div className={style.description}>{(badgeInfo.secondaryInfo || {}).instruction}</div>
        </div>
    );

    return (
        <Modal navigationTitle="View Badge" {...props} visible={props.badge}>
            <div className={style.content}>
                <div className={style.photo} style={{ backgroundImage: 'url(' + (badgeInfo.secondaryInfo || {}).photo + ')' }} />
                
                <div className={style.title}>{badgeInfo.name}</div>
                <div className={style.description}>{(badgeInfo.secondaryInfo || {}).description}</div>

                {additionalInfo()}
            </div>
        </Modal>
    );

};

export default BadgeShow;
