
import React, { useState, useEffect } from 'react';
import style from './style.module.css';

const TextField = props => {

    const [value, setValue] = useState('');

    const changeValue = e => {
        const update = e.target.value;
        setValue(update);
        props.onChange && props.onChange(update);
    };

    return (
        <input className={style.textField + ' i1'} 
            value={value}
            type={props.type || 'text'} 
            placeholder={props.placeholder || ''}
            onChange={changeValue} />
    );

};

export default TextField;
