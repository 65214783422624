
import React from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';

const GoalSelector = props => {

    const caps = string => string.charAt(0).toUpperCase() + string.slice(1);

    const category = category => {
        const className = conditional('category', style, { 
            selected: props.selected === category
        });

        return (
            <div className={className + ' h2'} onClick={() => props.onSelect(category)}>
                {caps(category)}
            </div>
        );
    };

    return (
        <div className={style.GoalSelector}>
            <div className={style.categories}>{props.categories.map(category)}</div>
        </div>
    );

};

export default GoalSelector;
