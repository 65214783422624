
import React, { useRef, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import TitleBar from '../TitleBar';

const Navigation = props => {

    const ref = useRef();

    const scrollToTop  = () => {
        if (!ref || !ref.current) return;
        ref.current.scrollTop = 0;
    };

    useEffect(() => { 
        if (props.visible) scrollToTop();
        if (!props.visible) setTimeout(() => scrollToTop(), 500);
    }, [props.visible]);

    const navigationClass = conditional('Navigation', style, props);
    const containerClass = conditional('container', style, props);

    return (
        <div className={navigationClass} ref={ref}>
            {!props.hideNavigationBar ? <TitleBar title={props.navigationTitle} /> : null}
            <div className={containerClass}>{props.children}</div>
        </div>
    );

};

export default Navigation;
