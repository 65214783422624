
const queries = {};

const debounce = (update, id = 'default', delay = 500) => async e => {
    const value = e.target.value;
    queries[id] = value;

    setTimeout(() => { 
        queries[id] === value && update(value) 
    }, delay);
};

export default { debounce };
