
import { request, storage, mock } from './';

const sendVerificationCode = async phone => {
        const r = await request('/auth/change-code', { phone }, false);
        console.log(r)
        return r.data;
};

const verifyCode = async (phone, code) => {
    try {
        const result = await request('/auth/sign-in', { phone, password: code }, false);
        storage.save(result.data);
        return result.data;
    } catch (e) {
        return null;
    }
};

const promptContact = async () => {
    try {
        const contact = await request('/auth/contact/fetch', {});
        return contact.data.prompt;
    } catch (e) {
        return false;
    }
};

const updateContact = async email => {
    const contact = await request('/auth/contact/update', { email });
    return contact.data.success;
};

const signUp = async (data) => {
    console.log(data)
    const result = await request('/auth/sign-up', data);
    console.log(JSON.stringify(result.data))
    // storage.save(result.data);
    return result.data;
};

export default { sendVerificationCode, verifyCode, signUp, promptContact, updateContact };
