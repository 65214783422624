
import React, { useState, useEffect, useRef } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';
import api from '../../api';
import List from './List';
import TextField from './TextField';
import PhotoUpload from './PhotoUpload';

const SecondaryInfoModal = props => {

    const [visible, setVisible] = useState(false);
    const [settings, setSettings] = useSmoothState([], 0.5);
    const [page, setPage] = useSmoothState(0, 0.5);

    const scrollContainer = useRef();

    const [updates, setUpdates] = useSmoothState({
        source: null,
        firstName: null,
        lastName: null,
        photo: null,
        goal: null,
        area: null,
        birthdayDay: null,
        birthdayMonth: null,
        birthdayYear: null,
        weightLbs: null,
        heightFt: null,
        heightIn: null
    }, 0.5);

    const update = (field, value) => setUpdates({ ...updates, [field]: value });

    useEffect(() => {
        console.log(updates);
    }, [updates]);

    const stepSources = async () => {
        return [
            // {
            //     id: 'apple-health',
            //     title: 'Apple Health',
            //     subtitle: 'Looks like you\'re using an iPhone! Select this option to use the built in step tracker.'
            // },
            {
                id: 'apple-health',
                title: 'Built In Health App',
                subtitle: 'If you have Apple Health or Google Fit, select this option to use the built in app.'
            },
            {
                id: 'fitbit',
                title: 'Fitbit',
                subtitle: 'Have a Fitbit? Select this option if you use it most often and want to leave your phone behind!'
            }
        ];
    };

    const getGoals = async () => {
        const goals = await api.goals.fetchAvailable();
        return goals.map(goal => ({ id: goal._id, title: goal.name, subtitle: goal.secondaryInfo.description }))
    };

    const getAreas = async () => {
        const areas = await api.areas.fetch();
        return areas.map(area => ({ id: area._id, title: area.name, subtitle: area.zip }));
    };

    const infoTypes = {
        source: {
            title: 'Select Step Source',
            subtitle: 'How do you want to track your steps?',
            fields: [
                {
                    component: List,
                    providerData: stepSources,
                    onChange: value => update('source', value)
                }
            ]
        },
        name: {
            title: 'Change Name',
            subtitle: 'Your name will appear on profile and in leaderboards.',
            fields: [
                {
                    component: TextField,
                    placeholder: 'First Name',
                    onChange: value => update('firstName', value)
                },
                {
                    component: TextField,
                    placeholder: 'Last Name',
                    onChange: value => update('lastName', value)
                }
            ]
        },
        photo: {
            title: 'Profile Photo',
            subtitle: 'Upload a photo to appear next to your name in leaderboards!',
            fields: [
                {
                    component: PhotoUpload,
                    placeholder: 'Photo Url',
                    onChange: value => update('photo', value)
                }
            ]
        },
        goals: {
            title: 'Set Goals',
            subtitle: 'What do you hope to achieve by using Active Marion Project?',
            fields: [
                {
                    component: List,
                    providerData: getGoals,
                    onChange: value => update('goal', value)
                }
            ]
        },
        area: {
            title: 'Select Zip Code',
            subtitle: 'Let us know what part of Marion County you live in.',
            fields: [
                {
                    component: List,
                    providerData: getAreas,
                    onChange: value => update('area', value)
                }
            ]
        },
        birthday: {
            title: 'Birthday',
            subtitle: 'This info is secure and will not be shown publicly in the app.',
            fields: [
                {
                    component: TextField,
                    type: 'number',
                    placeholder: 'Month',
                    onChange: value => update('birthdayMonth', value)
                },
                {
                    component: TextField,
                    type: 'number',
                    placeholder: 'Day',
                    onChange: value => update('birthdayDay', value)
                },
                {
                    component: TextField,
                    type: 'number',
                    placeholder: 'Year',
                    onChange: value => update('birthdayYear', value)
                }
            ]
        },
        weight: {
            title: 'Weight',
            subtitle: 'This info is secure and will not be shown publicly in the app.',
            fields: [
                {
                    component: TextField,
                    type: 'number',
                    placeholder: 'Lbs.',
                    onChange: value => update('weightLbs', value)
                }
            ]
        },
        height: {
            title: 'Height',
            subtitle: 'This info is secure and will not be shown publicly in the app.',
            fields: [
                {
                    component: TextField,
                    type: 'number',
                    placeholder: 'Ft.',
                    onChange: value => update('heightFt', value)
                },
                {
                    component: TextField,
                    type: 'number',
                    placeholder: 'In.',
                    onChange: value => update('heightIn', value)
                }
            ]
        }
    };

    useEffect(() => {
        if (!props.settings || !props.settings.length) return;
        setVisible(true);
        setSettings(props.settings);
    }, [props.settings]);

    const performUpdates = async () => {
        if (updates.source) {
            await api.accounts.sendActiveFit({ source: updates.source });
        }

        if (updates.firstName || updates.lastName) {
            if (updates.firstName.length < 1 || updates.lastName.length < 1) throw 'invalid-input';

            await api.accounts.AddUserInfo({
                firstName: updates.firstName || '',
                lastName: updates.lastName || ''
            });
        }

        if (updates.photo) {
            console.log(updates.photo)
            await api.accounts.AddUserInfo({
                secondaryInfo: updates.photo
            });
        }

        if (updates.goal) {
            await api.goals.setAppGoal(updates.goal);
        }

        if (updates.area) {
            await api.accounts.AddUserInfo({
                secondaryInfo: {
                    area: updates.area
                }
            });
        }

        if (updates.birthdayDay || updates.birthdayMonth || updates.birthdayYear) {
            if (!updates.birthdayDay || !updates.birthdayMonth || !updates.birthdayYear) throw 'invalid-input';

            await api.accounts.AddUserInfo({
                secondaryInfo: {
                    birthday: `${updates.birthdayMonth || 1}-${updates.birthdayDay || 1}-${updates.birthdayYear || 1980}`
                }
            });
        }

        if (updates.weightLbs) {
            await api.accounts.AddUserInfo({
                secondaryInfo: {
                    weightKilograms: parseInt(updates.weightLbs)
                }
            });
        }

        if (updates.heightFt || updates.heightIn) {
            if (!updates.heightFt || !updates.heightIn) throw 'invalid-input';

            await api.accounts.AddUserInfo({
                secondaryInfo: {
                    heightCentimeters: parseInt(updates.heightFt * 12) + parseInt(updates.heightIn)
                }
            });
        }
    };

    const next = async () => {
        scrollContainer.current.scrollTop = 0;
        try {
            await performUpdates();

            if (page >= settings.length - 1) return dismiss();
            setPage(page => page += 1);
            setUpdates('initial');
        } catch (e) {
            // set error
        }
    };

    const dismiss = () => {
        const editedIds = settings;

        setVisible(false);
        setPage('initial');
        setUpdates('initial');
        setSettings('initial');

        setTimeout(() => {
            props.onDismiss(editedIds);
        }, 600);
    };

    const card = setting => (
        <div className={style.card}>
            <h3 className={style.title}>{setting.title}</h3>
            <p className={style.description}>{setting.subtitle}</p>
            {setting.fields.map(field => {
                const Component = field.component;
                return <Component providerData={field.providerData} type={field.type} placeholder={field.placeholder} onChange={field.onChange} />
            })}
        </div>
    );

    const secondaryInfoClass = conditional('SecondaryInfoModal', style, { visible });

    const cardsStyle = { 
        width: 'calc(' + settings.length + ' * 100%)',
        transform: 'translateX(-' + (100 / settings.length) * page + '%)'
    };

    return (
        <div className={secondaryInfoClass}>
            <div className={style.container} onClick={e => e.stopPropagation()}>
                <div className={style.cards} ref={scrollContainer} style={cardsStyle}>
                    <div className={style.close} onClick={dismiss} />
                    {settings.map(setting => card(infoTypes[setting]))}
                </div>
            </div>
            <div className={style.buttonContainer}>
                <div className={style.done} onClick={next}>{page === settings.length - 1 ? 'Save' : 'Save'}</div>
            </div>
        </div>
    );

};

export default SecondaryInfoModal;
