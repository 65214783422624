
import React, { useState, useEffect } from 'react';
import style from './style.module.css';

const BadgeItem = props => {

    const itemData = {
        title: '',
        description: null,
        photo: null
    };

    if (props.itemType === 'badges') {
        itemData.title = props.data.name;
        itemData.description = props.data.secondaryInfo.description;
        itemData.photo = props.data.secondaryInfo.photo;
    }

    return (
        <div className={style.BadgeItem} onClick={props.onSelect}>
            <div className={style.content}>
                <div className={style.photo} style={{ backgroundImage: 'url(' + itemData.photo + ')' }} />
                <div className={style.info}>
                    <div className={style.title}>{itemData.title}</div>
                    <div className={style.description}>{itemData.description}</div>
                </div>
            </div>
        </div>
    );

};

export default BadgeItem;