
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';

const AlertDialog = props => {

    const [alert, setAlert] = useState(null);

    useEffect(() => {
        if (!props.alert) return;
        setAlert(props.alert);
    }, [props.alert]);

    const alertClass = conditional('AlertDialog', style, { visible: alert });

    const finish = () => {
        setAlert(null);
        props.onDone();
    };

    const confirm = async () => {
        setAlert(null);
        await props.alert.onConfirm();
    };

    const alertContent = () => alert ? (
        <div className={style.container}>
            {props.alert.photo ? (
                <div className={style.photo} style={{ backgroundImage: 'url(' + props.alert.photo + ')' }}>
                    
                </div>
            ) : null}
            <h3 className={style.title}>{props.alert.title}</h3>
            <p className={style.description}>{props.alert.description}</p>
            {props.alert.instructions ? (
                 <p className={style.description}>{props.alert.instructions}</p>
            ) : null}
            <div className={style.buttonContainer}>
                <div className={style.done + ' stat-text-c'} onClick={finish}>{props.alert.doneTitle || 'Done'}</div>
                {props.alert.onConfirm ? <div className={style.done + ' stat-text-c'} onClick={confirm}>{props.alert.confirmTitle || 'Confirm'}</div> : null}
            </div>
        </div>
    ) : null;

    return (
        <div className={alertClass}>
            {alertContent()}
        </div>
    );

};

export default AlertDialog;
