
import { request } from './';

const fetch = async () => {
    const result = await request('/rewards/fetch', {});
    return result.data.filter(result => !result.claimed);
};

const claim = async (group, choice) => {
    const result = await request('/rewards/claim', { group, choice });
    return result.data;
};

const fetchAvailable = async () => {
    const result = await request('/rewards/redemptions', {});
    return result.data.filter(result => !result.claimed);
};

const redeem = async redemption => {
    const result = await request('/rewards/redeem', { redemption });
    return result.data;
};

export default { fetch, claim, fetchAvailable, redeem };
