
import React, { useState, useEffect } from 'react';
import conditional from '../../utils/conditional';
import style from './style.module.css';

const List = props => {

    const [providedData, setProvidedData] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const fetchProviderData = async () => {
        const data = await props.providerData();
        setProvidedData(data);
    };

    useEffect(() => { fetchProviderData() }, []);

    const selectItem = index => {
        setSelectedIndex(index);
        props.onChange(providedData[index].id);
    };

    const item = (data, index) => (
        <div className={style.item} onClick={() => selectItem(index)}>
            <div className={style.info}>
                <div className={style.itemTitle}>{data.title}</div>
                <div className={style.itemSubtitle}>{data.subtitle}</div>
            </div>
            <div className={conditional('check', style, { visible: selectedIndex === index })} />
        </div>
    );

    return (
        <div className={style.list}>
            {providedData.map(item)}
        </div>
    );

};

export default List;
