
import React, { useEffect } from 'react';
import style from './style.module.css';

const SignOut = props => {

    const signOut = () => {
        localStorage.setItem('authData', null);
        props.onAuth(null);
        props.history.push('/');
    };

    useEffect(() => { signOut() }, []);

    return (
        <div className={style.SignOut}>
            
        </div>
    );

};

export default SignOut;
