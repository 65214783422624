
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import Navigation from '../Navigation';
import Collection from '../Collection';

import GoalItem from '../GoalItem';
import AddNewFooter from '../AddNewFooter';
import GoalsEdit from '../GoalsEdit';

const Goals = props => {

    const [showGoalsEdit, setShowGoalsEdit] = useState(false);

    const fetchData = props.fetchData || {};
    const goalProgress = fetchData.goalProgress || { daily: {}, weekly: {}, monthly: {}, quarterly: {} };

    const progressGoals = [
        goalProgress.daily.steps, 
        goalProgress.daily.points, 
        goalProgress.weekly.steps, 
        goalProgress.weekly.points, 
        goalProgress.monthly.steps, 
        goalProgress.monthly.points, 
        goalProgress.quarterly.steps, 
        goalProgress.quarterly.points
    ]
    .filter(goal => goal) // not all of these goals will be set for most ppl
    .sort((a, b) => b.percentage - a.percentage); 

    const saveGoal = () => {
        setShowGoalsEdit(false);
        props.resync();
    };
    
    return (
        <Navigation navigationTitle={'Personal Goals'}>
            <div className={style.content}>
                <Collection
                    listLayout
                    items={progressGoals}
                    itemType={'goals'}
                    component={GoalItem}
                    footerComponent={AddNewFooter}
                    onSelect={() => {}}
                    onSelectFooter={() => setShowGoalsEdit(true)} />
            </div>
            <GoalsEdit visible={showGoalsEdit} onFinish={saveGoal} />
        </Navigation>
    );

};

export default Goals;
