
import platform from 'geeky-platform';

const themes = {
    dark: {
        'background': 'rgb(26, 25, 25)',
        'background-blur': 'rgba(26, 25, 25, 0.7)',
        'background-blur-b': 'rgba(37, 34, 43, 0.7)',
        'background-r': 'rgb(26, 25, 25)',
        'card-r': 'rgb(37, 38, 40)',
        'button-bg': 'rgba(120, 120, 120, 0.1)',
        'button-text': 'rgba(255, 255, 255, 0.9)',
        // 'background-r': 'rgb(25, 25, 36)',
        'b1': 'rgb(46, 43, 42)',
        'h1': 'rgb(255, 255, 255)',
        'h15': 'rgba(255, 255, 255, 0.9)',
        'h2': 'rgba(255, 255, 255, 0.7)',
        'h3': 'rgba(255, 255, 255, 0.5)',
        'i1': 'rgb(255, 255, 255)',
        'i1-border': '2px solid rgba(255, 255, 255, 0.1)',
        'i1-border-focus': '2px solid rgba(255, 255, 255, 0.3)',
        'stat-background-a': 'rgba(120, 155, 234, 0.12)',
        'stat-background-b': 'rgba(234, 171, 120, 0.12)',
        'stat-background-c': 'rgba(103, 202, 163, 0.12)',
        'stat-background-d': 'rgba(245, 140, 136, 0.12)',
        'stat-text-a': 'rgba(120, 155, 234, 1.0)',
        'stat-text-b': 'rgba(234, 171, 120, 1.0)',
        'stat-text-c': 'rgba(103, 202, 163, 1.0)',
        'stat-text-d': 'rgba(245, 140, 136, 1.0)',
        'stat-subtext-a': 'rgba(120, 155, 234, 1.0)',
        'stat-subtext-b': 'rgba(234, 171, 120, 1.0)',
        'stat-subtext-c': 'rgba(103, 202, 163, 1.0)',
        'stat-subtext-d': 'rgba(245, 140, 136, 1.0)',
        'wheel-fill': 'rgba(103, 202, 163, 1.0)',
        'wheel-bg': 'rgba(103, 202, 163, 0.09)',
        'wheel-text': 'rgba(103, 202, 163, 1.0)',
        'bg-a': 'rgba(233, 160, 72, 0.12)',
        'bg-b': 'rgba(161, 156, 155, 0.12)',
        'bg-c': 'rgba(222, 193, 153, 0.12)',
        'bg-d': 'rgba(245, 140, 136, 0.0)',
        'fg-0-a': 'rgba(249, 197, 125, 1.0)',
        'fg-0-b': 'rgba(161, 156, 155, 1.0)',
        'fg-0-c': 'rgba(222, 193, 153, 1.0)',
        'fg-0-d': 'rgba(255, 255, 255, 0.5)',
        'fg-1-a': 'rgba(255, 255, 255, 0.8)',
        'fg-1-b': 'rgba(255, 255, 255, 0.8)',
        'fg-1-c': 'rgba(255, 255, 255, 0.8)',
        'fg-1-d': 'rgba(255, 255, 255, 0.8)',
        'fg-2-a': 'rgba(255, 255, 255, 0.5)',
        'fg-2-b': 'rgba(255, 255, 255, 0.5)',
        'fg-2-c': 'rgba(255, 255, 255, 0.5)',
        'fg-2-d': 'rgba(255, 255, 255, 0.5)',
        'back': 'url(/arrow-light.svg)',
        'close': 'url(/close-light.svg)',
        'step-up': 'url(/step-up-light.svg)',
        'step-down': 'url(/step-down-light.svg)',
        'plus': 'url(/plus-light.svg)',
        'minus': 'url(/minus-light.svg)',
        'edit': 'url(/edit-light.svg)',
        'standard-border': '1px solid rgba(255, 255, 255, 0.1)',
        'lock': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%2367CAA3'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h2c0-1.66 1.34-3 3-3s3 1.34 3 3v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z'/%3E%3C/svg%3E")`,
        'unlock': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23FFFFFF'%3E%3Cg fill='none'%3E%3Cpath d='M0 0h24v24H0V0z'/%3E%3Cpath d='M0 0h24v24H0V0z' opacity='.87'/%3E%3C/g%3E%3Cpath d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z'/%3E%3C/svg%3E")`,
        'nav-close': `url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23FFFFFF'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'/%3E%3C/svg%3E")`
    },
    light: {
        'background': 'rgb(254, 248, 246)',
        'background-blur': 'rgba(254, 248, 246, 0.7)',
        'background-blur-b': 'rgba(254, 248, 246, 0.7)',
        'background-r': 'rgb(254, 248, 246)',
        'button-bg': 'rgba(48, 45, 56, 0.1)',
        'button-text': 'rgba(51, 51, 51, 0.7)',
        'card-r': 'rgb(247, 236, 235)',
        'b1': 'rgb(232, 230, 230)',
        'h1': 'rgb(51, 51, 51)',
        'h15': 'rgba(51, 51, 51, 0.9)',
        'h2': 'rgba(51, 51, 51, 0.6)',
        'h3': 'rgba(51, 51, 51, 0.45)',
        'i1': 'rgb(51, 51, 51)',
        'i1-border': '2px solid rgba(51, 51, 51, 0.1)',
        'i1-border-focus': '2px solid rgba(51, 51, 51, 0.3)',
        'stat-background-a': 'rgba(120, 155, 234, 0.12)',
        'stat-background-b': 'rgba(234, 171, 120, 0.12)',
        'stat-background-c': 'rgba(103, 202, 163, 0.12)',
        'stat-background-d': 'rgba(245, 140, 136, 0.12)',
        'stat-text-a': 'rgba(120, 155, 234, 1.0)',
        'stat-text-b': 'rgba(234, 171, 120, 1.0)',
        'stat-text-c': 'rgba(103, 202, 163, 1.0)',
        'stat-text-d': 'rgba(245, 140, 136, 1.0)',
        'stat-subtext-a': 'rgba(0, 0, 0, 0.25)',
        'stat-subtext-b': 'rgba(0, 0, 0, 0.25)',
        'stat-subtext-c': 'rgba(0, 0, 0, 0.25)',
        'stat-subtext-d': 'rgba(0, 0, 0, 0.25)',
        'wheel-fill': 'rgba(103, 202, 163, 1.0)',
        'wheel-bg': 'rgba(245, 243, 242, 1.0)',
        'wheel-text': 'rgb(51, 51, 51)',
        'bg-a': 'rgba(233, 160, 72, 0.12)',
        'bg-b': 'rgba(161, 156, 155, 0.12)',
        'bg-c': 'rgba(222, 193, 153, 0.12)',
        'bg-d': 'rgba(245, 140, 136, 0.0)',
        'fg-0-a': 'rgba(249, 197, 125, 1.0)',
        'fg-0-b': 'rgba(161, 156, 155, 1.0)',
        'fg-0-c': 'rgba(222, 193, 153, 1.0)',
        'fg-0-d': 'rgba(0, 0, 0, 0.2)',
        'fg-1-a': 'rgba(0, 0, 0, 0.6)',
        'fg-1-b': 'rgba(0, 0, 0, 0.6)',
        'fg-1-c': 'rgba(0, 0, 0, 0.6)',
        'fg-1-d': 'rgba(0, 0, 0, 0.6)',
        'fg-2-a': 'rgba(0, 0, 0, 0.2)',
        'fg-2-b': 'rgba(0, 0, 0, 0.2)',
        'fg-2-c': 'rgba(0, 0, 0, 0.2)',
        'fg-2-d': 'rgba(0, 0, 0, 0.2)',
        'back': 'url(/arrow-dark.svg)',
        'close': 'url(/close-dark.svg)',
        'step-up': 'url(/step-up-dark.svg)',
        'step-down': 'url(/step-down-dark.svg)',
        'plus': 'url(/plus-dark.svg)',
        'minus': 'url(/minus-dark.svg)',
        'edit': 'url(/edit-dark.svg)',
        'standard-border': '1px solid rgba(51, 51, 51, 0.1)',
        'lock': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23333333'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h2c0-1.66 1.34-3 3-3s3 1.34 3 3v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z'/%3E%3C/svg%3E")`,
        'unlock': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%2367CAA3'%3E%3Cg fill='none'%3E%3Cpath d='M0 0h24v24H0V0z'/%3E%3Cpath d='M0 0h24v24H0V0z' opacity='.87'/%3E%3C/g%3E%3Cpath d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z'/%3E%3C/svg%3E")`,
        'nav-close': `url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23777777'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'/%3E%3C/svg%3E")`
    }
};

const toggleTheme = mode => {
    const theme = (localStorage.getItem('theme') || 'light') === 'light' ? 'dark' : 'light';
    changeTheme(theme);
};

const changeTheme = mode => {
    updateProperties(themes[mode]);
    localStorage.setItem('theme', mode);
    platform.request({ id: 'changeAppearance', data: { mode }});
};

const configure = () => {
    const mode = localStorage.getItem('theme') || 'light';
    updateProperties(themes[mode]);
    platform.request({ id: 'changeAppearance', data: { mode }});
};

const updateProperties = props => {
    for (const property in props) {
        const value = props[property];
        const style = document.documentElement.style;
        style.setProperty('--appearance-' + property, value);
    }
};

const appearanceClass = (name, style) => name + ' ' + style;

export default { changeTheme, configure, toggleTheme, appearanceClass };
