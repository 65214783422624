
import React from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import { Link } from 'react-router-dom';

const TabBar = props => {

    const tabs = [
        {
            icon: 'home',
            path: '/'
        },
        {
            icon: 'trending_up',
            path: '/goals'
        },
        {
            icon: 'group',
            path: '/groups'
        },
        {
            icon: 'verified',
            path: '/badges'
        },
        {
            icon: 'settings',
            path: '/settings'
        }
    ];

    const tab = (tab, index) => {
        const className = conditional('tab', style, { 
            selected: props.history.location.pathname === tab.path 
        });

        return (
            <Link className={className + ' h1'} to={tab.path}>
                <span className="material-icons">{tab.icon}</span>
            </Link>
        );
    };

    return (
        <div className={style.TabBar + ' background'}>
            <div className={style.tabs}>{tabs.map(tab)}</div>
        </div>
    );

};

export default TabBar;
