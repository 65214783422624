
import React from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';

const GroupSelector = props => {

    const category = (category, index) => {
        const className = conditional('category', style, { 
            selected: props.selectedIndex === index
        });

        return (
            <div className={className + ' h2'} onClick={() => props.onSelect(index)}>
                {category}
            </div>
        );
    };

    return (
        <div className={style.GroupSelector}>
            <div className={style.categories}>{props.categories.map(category)}</div>
        </div>
    );

};

export default GroupSelector;
