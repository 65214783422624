
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import Navigation from '../Navigation';
import Collection from '../Collection';
import api from '../../api';
import BadgeItem from '../BadgeItem';
import BadgeShow from '../BadgeShow';
import GroupSelector from '../GroupSelector';

const Badges = props => {

    const [badges, setBadges] = useState();
    const [selectedBadge, setSelectedBadge] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(0);

    const fetchData = async () => {
        try {
            const data = await api.badges.fetchBadges();
            setBadges(data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => { fetchData() }, []);

    const badgeList = (badges || [])
        .filter(badge => selectedCategory === 0 ? badge.earned : !badge.earned && !badge.past)
        // .sort((a, b) => b.groupInfo.rank - a.groupInfo.rank);

    return (
        <Navigation navigationTitle={'Badges'}>
            <div className={style.content}>
                <div className={style.selector}>
                    <GroupSelector 
                        categories={['Earned', 'Not Earned']} 
                        selectedIndex={selectedCategory} 
                        onSelect={setSelectedCategory} />
                </div>
                <Collection
                    listLayout
                    items={badgeList}
                    itemType={'badges'}
                    component={BadgeItem}
                    footerComponent={null}
                    onSelect={item => setSelectedBadge(item)} /> 
            </div>
            <BadgeShow badge={selectedBadge} onFinish={() => setSelectedBadge(null)} />
        </Navigation>
    );

};

export default Badges;
