
import React, { useEffect, useState } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';

const RewardsBanner = props => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
    }, []);

    const bannerClass = conditional('RewardsBanner', style, { visible });

    return (
        <div className={bannerClass} onClick={() => props.onSelect()}>
            <div className={style.icon} />
            <div className={style.info}>
                <div className={style.title}>{props.unclaimed > 0 ? (props.unclaimed + ' New Rewards') : 'Your Rewards'}</div>
                {/* <div className={style.description}>Tap here to claim your rewards</div> */}
            </div>
        </div>
    );

};

export default RewardsBanner;
