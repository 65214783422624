
const subscriptions = {};
const callbackRefs = {};

const subscribe = (id, callback) => {
    const refId = Math.random().toString(36);
    callbackRefs[refId] = callback;

    const subscription = subscriptions[id];
    subscription ? subscription.push(refId) : subscriptions[id] = [refId];
};

const publish = (id, data) => {
    subscriptions[id].forEach(refId => callbackRefs[refId](data));
};

export default { subscribe, publish };
