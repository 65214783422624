
import React from 'react';
import style from './style.module.css';

const GoalStepper = props => {

    const increment = value => {
        if (props.value + value < 0) return;
        props.onIncrement(value);
    };

    return (
        <div className={style.GoalStepper}>
            <div className={style.spacer} />
            <div className={style.number}>{props.value}</div>
            <div className={style.arrows}>
                <div className={style.arrow + ' ' + style.up} onClick={() => increment(props.incrementValue)} />
                <div className={style.arrow + ' ' + style.down} onClick={() => increment(-props.incrementValue)} />
            </div>
        </div>
    );

};

export default GoalStepper;
