
import React from 'react';
import style from './style.module.css';
import Modal from '../Modal';
import GroupStartModal from '../GroupStartModal';

const GroupStart = props => {

    return (
        <GroupStartModal {...props} navigationTitle="Start a Group">

        </GroupStartModal>
    );

};

export default GroupStart;
