
import React from 'react';
import style from './style.module.css';
import Modal from '../Modal';
import GroupJoinModal from '../GroupJoinModal';

const GroupJoin = props => {

    return (
        <GroupJoinModal {...props} navigationTitle="Join a Group">

        </GroupJoinModal>
    );

};

export default GroupJoin;
