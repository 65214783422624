
import React, { useState } from 'react';
import style from './style.module.css';

import uuidv4 from '../../utils/uuidv4';
import { app } from '../../base';

const PhotoUpload = props => {

    const [loading, setLoading] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);

    const onFileChange = async e => {
        if (!e.target || !e.target.files || e.target.files.length < 1) return;
        setLoading(true);

        const file = e.target.files[0]
        const tImage = await resizeImageToThumbnail(file);

        // const photo = await uploadPhoto(file);
        const thumbnail = await uploadPhotoThumbnail(tImage);

        props.onChange({ photo: thumbnail, thumbnail });
        setLoading(false);
        setThumbnail(thumbnail);

        // Cleanup File Input
        if (e.target && e.target.value) {
            e.target.value = "";
        }
    }

    const uploadPhoto = async file => {
        console.log('this should break it but will help us', file)
        if(!file){
            console.log('schiese lets go')
        } else {        
        const p = new Promise((resolve, reject) => {
            const storageRef = app.storage().ref()
            const fileID = uuidv4();
            // Upload Original File
            const fileref = storageRef.child(`images/originals/${fileID}-${file.name}`)
            const uploadTask = fileref.put(file);
            uploadTask.on('state_changed', snap => {
                var progress = (snap.bytesTransferred / snap.totalBytes) * 50;
                // setPhotoProgress(progress)
                // console.log('Upload is ' + progress + '% done');
            }, function (error) {
                props.error(error);
                reject(error)
            }, function () {
                uploadTask.snapshot.ref.getDownloadURL().then(url => {
                    resolve(url)
                });
            })
        })
        let url = '';
        await p.then(u => {
            url = u;
        })
        return url
    }
    }

    const uploadPhotoThumbnail = async imgSrc => {
        if(!imgSrc){
            console.log('schiese lets go')
        } else {  
        const p = new Promise((resolve, reject) => {
            const storageRef = app.storage().ref()
            const fileID = uuidv4();
            // Upload Thumbnail
            const thumbref = storageRef.child(`images/thumbnails/${fileID}.jpg`)
            const thumbTask = thumbref.putString(imgSrc, 'data_url')
            thumbTask.on('state_changed', snap => {
                var progress = (snap.bytesTransferred / snap.totalBytes) * 50;
                // setThumbProgress(progress)
                // console.log('Upload is ' + progress + '% done');
            }, function (error) {
                console.log(error);
                props.error(error);
                reject(error)
            }, function () {
                thumbTask.snapshot.ref.getDownloadURL().then(url => {
                    resolve(url);
                });
            })
        })
        let url = '';
        await p.then(u => {
            url = u;
        })
        return url
    }
    }

    const resizeImageToThumbnail = img => new Promise(resolve => {
        const image = new Image();
        image.onload = _ => {
            const resized = shrinkImage(image);
            resolve(resized.src);
        };
        image.src = URL.createObjectURL(img);
    });

    const shrinkImage = (img) => {
        // Create Canvas
        const canvas = document.createElement('canvas');
        canvas.style.display = "none";
        let ctx = canvas.getContext('2d');
        const size = 200;
        canvas.width = size;
        canvas.height = size;
        document.body.appendChild(canvas);

        // Calculate Size
        const offsetHorizontal = img.width >= img.height;
        const offsetPercentage = Math.abs((img.width - img.height) / 2) / (offsetHorizontal ? img.width : img.height);
        const offset = 0 - (offsetPercentage * size);
        const imageRatio = offsetHorizontal ? (img.width / img.height) : (img.height / img.width);
        const x = offsetHorizontal ? offset : 0;
        const y = !offsetHorizontal ? offset : 0;
        const width = offsetHorizontal ? size * imageRatio : size;
        const height = !offsetHorizontal ? size * imageRatio : size;
        ctx.drawImage(img, x, y, width, height);
        // Convert back to Image
        const tImage = new Image();
        tImage.src = canvas.toDataURL("image/png");

        // Cleanup DOM Elements and Revoke URLs
        document.body.removeChild(canvas)
        URL.revokeObjectURL(img.src)

        // Return resized Image
        return tImage;
    }

    const photoStyle = {
        backgroundImage: `url(${thumbnail})`
    };

    return (
        <div className={style.photoUpload} style={photoStyle}>
            {!loading && !thumbnail ? <div className={style.add}>+</div> : null}
            <input className={style.input} type="file" accept="image/jpeg" onChange={onFileChange} />
            {loading ? <div className={style.loading}>Uploading...</div> : null}
        </div>
    );

};

export default PhotoUpload;
