
import React, { useRef, useEffect, useState } from 'react';
import style from './style.module.css';
import timing from '../../utils/timing'
import conditional from '../../utils/conditional';
import TitleBar from '../TitleBar';
import api from '../../api';
import Collection from '../Collection';
import groupItem from '../RequestItem';
let firstScrollY = 0;

const GroupJoinModal = props => {

    const [data, setData] = useState({ name: '' });
    const [groupsShown, setGroupsShown] = useState([])
    const ref = useRef();

    const updateData = (field, e) => {
        const value = e.target.value;
        setData(data => ({ ...data, [field]: value }));
    };

    const scrollToTop = () => {
        if (!ref || !ref.current) return;
        ref.current.scrollTop = 0;
    };
    const handleChange = async (value) => {
        try {
            console.log(value)
            const results = await api.groups.searchGroups({ 'search': `${value}` });
            setGroupsShown(results)
        } catch (e) {
            console.log(e)

        }
    }
    useEffect(() => {
        if (props.visible) {
            firstScrollY = window.scrollY;
            scrollToTop();
        }

        if (!props.visible) {
            window.scrollTo(0, firstScrollY);
            setTimeout(() => scrollToTop(), 500);
        }
    }, [props.visible]);

    const modalClass = conditional('Modal', style, props);

    return (
        <div className={modalClass + ' background'} ref={ref}>
            <TitleBar title={props.navigationTitle} onBack={props.onFinish} />
            <div className={style.content}>
                {props.children}
                <input type='text' onChange={timing.debounce(handleChange)} placeholder="Group Name..." className={style.groupInput} />
                <Collection
                listLayoutss
                refresh={props}
                items={groupsShown}
                itemType={'group'}
                component={groupItem}
                footerComponent={null}
                onSelect={() => {console.log('yo')}}/>
            </div>
        </div>
    );

};

export default GroupJoinModal;
