
import React from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';

const Collection = props => {

    const Component = props.component;

    const components = (props.items || []).map((item, index) => (
        <Component 
            key={index} 
            data={{ ...item, index }}
            itemType={props.itemType}
            refresh={props.refresh}
            onSelect={() => props.onSelect && props.onSelect(item)} />
    ));

    if (props.footerComponent) {
        const Footer = props.footerComponent;

        components.push((
            <Footer 
                key={'footer'} 
                onSelect={() => props.onSelect && props.onSelectFooter()} />
        ));
    }

    const collectionClass = conditional('Collection', style, props);
    return <div className={collectionClass}>{components}</div>;

};

export default Collection;
