
import React from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';

const TitleBar = props => {

    const loadingClass = conditional('loading', style, { visible: props.loading });
    const backClass = conditional('item', style, { back: props.onBack });

    const back = () => props.onBack && props.onBack();

    return (
        <div className={style.TitleBar + ' background'}>
            <div className={style.content}>
                <button className={backClass} onClick={back} />
                <div className={style.title + ' h1'}>{props.title}</div>
                <button className={style.item}></button>
            </div>
            <div className={loadingClass} />
        </div>
    );

};

export default TitleBar;
