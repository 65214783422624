import { request } from './';

// TODO: yes there will be another kind of goal thats not personal

const getAnalytics = async (data) => {
    const result = await request('/organizations/pull', data);
    return result.data;
};



export default { getAnalytics };