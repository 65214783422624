
import { request } from './';

// TODO: yes there will be another kind of goal thats not personal

const fetchAvailable = async () => {
    const result = await request('/goals/available/fetch', {});
    return result.data;
};

const setAppGoal = async goal => {
    const result = await request('/goals/replace', { goal });
    return result.data;
};

const fetchPersonal = async () => {
    const result = await request('/goals/custom/fetch', {});
    return result.data;
};

const addPersonal = async goal => {
    const result = await request('/goals/custom/add', goal);
    console.log(result)
    return result.data;
};

export default { fetchAvailable, setAppGoal, fetchPersonal, addPersonal };
