
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import platform from 'geeky-platform';

const baseLink = 'https://activemarion.app/l/';

const ShareDialog = props => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!props.shareData) return;
        setVisible(true);
    }, [props.shareData]);

    const share = service => {
        const link = props.shareData || {};

        let message = '';
    
        if (link.rank) {
            message = `I'm #${link.rank.rank} in ${link.rank.name} on Active Marion Project!\n${baseLink}${link.code}`;
        }
    
        if (link.goal) {
            console.log('goal')
            const percent = Math.round(link.goal.percentage * 100);
            message = `I reached my ${link.goal.time} ${link.goal.type} goal by ${percent}%!\n${baseLink}${link.code}`;
        }
    
        if (link.badge) {
            message = `I earned the ${link.badge.name} badge!\n${baseLink}${link.code}`;
        }

        platform.request({ id: 'shareApp', data: { service, message }});
    };

    const shareClass = conditional('ShareDialog', style, { visible });

    return (
        <div className={shareClass} onClick={() => setVisible(false)}>
            <div className={style.container} onClick={e => e.stopPropagation()}>
                {/* <div className={style.social + ' ' + style.facebook} onClick={() => share('facebook')} /> */}
                <div className={style.social + ' ' + style.message} onClick={() => share('message')} />
                {/* <div className={style.social + ' ' + style.instagram} onClick={() => share('instagram')} /> */}
                {/* <div className={style.social + ' ' + style.twitter} onClick={() => share('twitter')} /> */}
            </div>
        </div>
    );

};

export default ShareDialog;
