
import React from 'react';
import style from './style.module.css';

const HistoryItem = props => {
    return (
        <div className={style.Item + ' ' + style['g4']} onClick={props.onSelect}>
            <div className={style.info}>
                <div className={style.title}>{props.data.value.toLocaleString() + ' ' + props.itemType}</div>
                <div className={style.description}>{'on ' + (new Date(props.data.date.split('T')[0] + 'T00:00:00.000' + props.data.date.split('-')[1])).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}</div>
            </div>
        </div>
    );

};

export default HistoryItem;
