
import React from 'react';
import style from './style.module.css';

const GoalItem = props => {

    const caps = string => string.charAt(0).toUpperCase() + string.slice(1);

    return (
        <div className={style.Item + ' ' + style['g4']} onClick={props.onSelect}>
            <div className={style.info}>
                <div className={style.title}>{props.data.goal.toLocaleString() + ' ' + caps(props.data.time || '') + ' ' + caps(props.data.type || '')}</div>
                <div className={style.description}>{'Added on ' + (new Date(props.data.date)).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}</div>
            </div>
            <div className={style.rank}>
                <div className={style.value}>{props.data.goal.rank}</div>
                <div className={style.minus} />
            </div>
        </div>
    );

};

export default GoalItem;
