
import React from 'react';
import style from './style.module.css';
import formatPhone from '../../utils/formatPhone';

import Navigation from '../Navigation';

const Profile = props => {

    const settings = [
        {
            id: 'source',
            label: 'Activity Source',
            getText: data => {
                if (!data) return 'Select Activity Source';
                if (data === 'apple-health') return 'Built In Health App';
                if (data === 'fitbit') return 'Fitbit';
                return data;
            }
        },
        {
            id: 'goals',
            label: 'Goals',
            getText: data => {
                if (!data || !data.length) return 'Set Your Goals'
                return data.map(goal => goal.name).join(', ');
            }
        },
        {
            id: 'area',
            label: 'Area',
            source: 'secondaryInfo',
            getText: data => {
                if (!data) return 'Select Zip Code'
                return `${data.name} (${data.zip})`;
            }
        },
        {
            id: 'birthday',
            label: 'Birthday',
            source: 'secondaryInfo',
            getText: data => {
                if (!Date.parse(data)) return 'Enter Birthday';

                const date = new Date(data);
                const text = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
                return text;
            }
        },
        {
            id: 'weightKilograms',
            label: 'Weight',
            source: 'secondaryInfo',
            infoId: 'weight',
            getText: data => { 
                if (!data) return 'Enter Your Weight'
                return data + ' lbs.';
            }
        },
        {
            id: 'name',
            label: 'Edit Name',
            getText: data => {
                return 'Enter Your Name';
            }
        },
        {
            id: 'heightCentimeters',
            label: 'Height',
            source: 'secondaryInfo',
            infoId: 'height',
            getText: data => {
                if (!data) return 'Enter Your Height';

                const feet = Math.floor(data / 12);
                const inches = data - (feet * 12);
                return feet + ' ft. ' + inches + ' in.';
            }
        }
    ];

    const editSettings = id => props.promptUserInfo([id]);
    
    const settingsItem = setting => {

        const info = setting.source ? (props.profileUser || {})[setting.source] || {} : props.profileUser || {};
        const value = setting.getText(info[setting.id]);

        return (
            <div className={style.setting} onClick={() => editSettings(setting.infoId || setting.id)}>
                <div className={style.info}>
                    <div className={style.label}>{setting.label}</div>
                    <div className={style.value}>{value}</div>
                </div>
                <div className={style.edit} />
            </div>
        );

    };

    const profile = props.profileUser || {};

    return (
        <Navigation navigationTitle={'Profile'}>
            <div className={style.content}>
                <div className={style.profile}>
                    <div className={style.photo} style={{ backgroundImage: `url(${(profile.secondaryInfo || {}).thumbnail})` }} onClick={() => editSettings('photo')} />
                    <div className={style.title} onClick={() => editSettings('name')}>{(profile.firstName || '') + ' ' + (profile.lastName || '')}</div>
                    <div className={style.subtitle}>{formatPhone(profile.phone || '')}</div>
                </div>
                <div className={style.settings}>
                    {settings.map(settingsItem)}
                </div>
            </div>
        </Navigation>
    );

};

export default Profile;
