
import React, { useRef, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import TitleBar from '../TitleBar';

let firstScrollY = 0;

const Modal = props => {

    const ref = useRef();

    const scrollToTop  = () => {
        if (!ref || !ref.current) return;
        ref.current.scrollTop = 0;
    };

    useEffect(() => { 
        if (props.visible) {
            firstScrollY = window.scrollY;
            scrollToTop();
        }

        if (!props.visible) {
            window.scrollTo(0, firstScrollY);
            setTimeout(() => scrollToTop(), 500);
        }
    }, [props.visible]);

    const modalClass = conditional('Modal', style, props);

    return (
        <div className={modalClass + ' background'} ref={ref}>
            <TitleBar title={props.navigationTitle} onBack={props.onFinish} />
            <div className={style.content}>
                {props.children}
            </div>
        </div>
    );

};

export default Modal;
