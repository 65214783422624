
import conditional from './conditional';

export const renderState = (prop, renders) => {
    const render = renders[prop];
    if (!render) return null;
    return render();
};

export const visibleState = (style, prop, renders) => {
    const rendered = [];

    for (const state in renders) {
        const className = conditional(state, style, { visible: prop === state });
        rendered.push(renders[state](className));
    }

    return rendered;
};
