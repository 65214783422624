
const validate = phone => {
    if (phone.length < 11) return false;
    return true;
};

const format = string => string
    .replace(/[^\d]/g, '')
    .split(' ').join('')
    .split('+').join('')
    .split('(').join('')
    .split(')').join('')
    .split('-').join('');

export default { validate, format };
