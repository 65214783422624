
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import axios from 'axios';
import platform from 'geeky-platform';
import { request } from '../../api';
const AlertDialog = props => {
    const [alert, setAlert] = useState(null);
    const [data, setData] = useState({email: '', password: '' });
    const [showInvalidError, setShowInvalidError] = useState(false);
    const [token, setToken] = useState('');
    const items = [
        { label: "Apple Health", value: "apple-health" },
        { label: "FitBit", value: "fitbit" }
    ];

    const handleToken = string => {
        const result = JSON.parse(string);
        if (!result.token) return;
        setToken(result.token);
    };

    useEffect(() => {
        if (!props.alert) return;
        setAlert(props.alert);

        check();

        window.webAuthAndroid = data => {
            const jsonString = JSON.stringify(data);
            handleToken(jsonString);
        };

        platform.subscribeJS({ id: 'webAuthManager' }, data => {
            // platform.request({ id: 'webAuthManager', data: { url: 'test' } });
            // setToken(data.test);

            const jsonString = JSON.stringify(data);
            handleToken(jsonString);
        });

        setTimeout(() => {
            
        }, 500);
    }, [props.alert]);

    const alertClass = conditional('AlertDialog', style, { visible: alert });
    const updateData = (field, e) => {
        const value = e.target.value;
        setData(data => ({ ...data, [field]: value.trim() }));
    };

    const finish = () => {
        setAlert(null);
        setShowInvalidError(false)
        props.onDone();
    };

    const wait = time => new Promise(resolve => setTimeout(() => { resolve() }, time));

    const check = async () => {
        try {
            const checkResult = await request('/accounts/check', {});
            console.log(checkResult)

            if (checkResult.data.source === 'fitbit' && checkResult.data.data) {
                console.log('signed into fitbit');
                await props.alert.onConfirm('done');

                setAlert(null);
                setShowInvalidError(false);

                // setAlert(null);
                // setShowInvalidError(false);
                // props.onDone();

                return;
            }
        } catch (e) {}
        
        await wait(2000);
        await check();
    };

    const authenticate = () => {
        const saved = localStorage.getItem('authData');
        const auth = JSON.parse(saved);
        platform.request({ id: 'webAuthManager', data: { url: 'https://api.activemarion.app/accounts/fitbit/start?token=' + auth.token } });
    };

    const confirm = async () => {
        const url = 'https://api.fitbit.com/oauth2/token';
            try {
                const userData = `client_id=23RKJL&code=${'2043f99caba56b2cf4497a6509dcb878a6dcb4b0'}&grant_type=authorization_code&expires_in=31536000`;
                // const userData = `grant_type=password&username=${data.email}&password=${data.password}&client_id=228TQF&access_token=on&enableRefreshToken=true&expires_in=31536000`;
                const result = await axios.post(url, userData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Basic MjNSS0pMOjhjMjhjMmNjMDVlYjI2ZGE2MWNlMzc5YzQzYWQ4YWM2' }})
                console.log(result.data)
                await props.alert.onConfirm(result.data.access_token);
            } catch (e) {
                    console.log(e)
                        const error = new Error('Error getting token from Fitbit');
                        error.code = 'invalid-credentials';
                        console.log(error.code)
                        if (error.code === 'invalid-credentials') {
                            setShowInvalidError(true);
                        }
                        return error
            }
        // send user data back up to app level
        // await props.alert.onConfirm(activeFit);
        setAlert(null);
        setShowInvalidError(false)
    };
    const alertContent = () => alert ? (
        <div className={style.container}>
            <h3 className={style.title}>{(props.alert || {}).title}</h3>
            <p className={style.description}>{(props.alert || {}).description}</p>
                {/* <input className={style.textField + ' i1'} placeholder="Email" type='Email' onChange={e => updateData('email', e)} /> */}
                {/* <input className={style.textField + ' i1'} type='password' placeholder="Password" onChange={e => updateData('password', e)} /> */}
                {showInvalidError ? <div className={style.descriptionError + ' h2'}>Invalid Fitbit email/password, please try again.</div> : null}
            <div className={style.buttonContainer}>
                <div className={style.action} onClick={() => authenticate()}>Sign In</div>
                {/* <br /> */}
                {/* <div className={style.done} onClick={finish}>{props.alert.doneTitle || 'Done'}</div> */}
                {/* {props.alert.onConfirm ? <div className={style.done + ' stat-text-c'} onClick={(confirm)}>{props.alert.confirmTitle || 'Confirm'}</div> : null} */}
            </div>
            <div className={style.buttonContainer}>
                {/* <div className={style.action} onClick={() => authenticate()}>Sign In</div> */}
                {/* <br /> */}
                <div className={style.done} onClick={finish}>{(props.alert || {}).doneTitle || 'Done'}</div>
                {/* {props.alert.onConfirm ? <div className={style.done + ' stat-text-c'} onClick={(confirm)}>{props.alert.confirmTitle || 'Confirm'}</div> : null} */}
            </div>
        </div>
    ) : null;

    return (
        <div className={alertClass}>
            {alertContent()}
        </div>
    );

};

export default AlertDialog;
