
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';
import api from '../../api';
import publisher from '../../utils/publisher';

const NotificationModal = props => {

    const [visible, setVisible] = useState(false);
    const [notifications, setNotifications] = useSmoothState([], 0.5);
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (!props.notifications || !props.notifications.length) return;

        setVisible(true);
        setNotifications(props.notifications);
    }, [props.notifications]);

    const next = () => {
        if (page >= notifications.length - 1) return dismiss();
        setPage(page => page += 1);
    };

    const dismiss = () => {
        setVisible(false);
        setNotifications('initial');
        // props.onDismiss();
    };

    const share = async () => {
        const notification = notifications[page];
        if (!notification) return;

        const shareData = notification.shareData;
        if (!shareData) return;

        const shareLink = await api.links.add(shareData);
        publisher.publish('shareOptions', shareLink);
    };

    const notificationClass = conditional('NotificationModal', style, { visible });

    const cardsStyle = { 
        width: 'calc(' + notifications.length + ' * 100%)',
        transform: 'translateX(-' + (100 / notifications.length) * page + '%)'
    };

    const card = notification => (
        <div className={style.card}>
            <div className={style.photo} style={{ backgroundImage: 'url(' + notification.photo + ')' }} />
            <h3 className={style.title}>{notification.title}</h3>
            <p className={style.description}>{notification.subtitle}</p>
            <div className={style.share} onClick={share}>
                <span className={'stat-text-c'}>Share This!</span>
            </div>
        </div>
    );

    return (
        <div className={notificationClass} onClick={dismiss}>
            <div className={style.container} onClick={e => e.stopPropagation()}>
                <div className={style.cards} style={cardsStyle}>
                    {notifications.map(notification => card(notification))}
                </div>
                <div className={style.buttonContainer}>
                    <div className={style.done} onClick={next}>{page === notifications.length - 1 ? 'Finish' : 'Next'}</div>
                </div>
            </div>
        </div>
    );

};

export default NotificationModal;
