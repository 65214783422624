
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import Modal from '../Modal';
import Collection from '../Collection';

import HistoryItem from '../HistoryItem';

const HistoryModal = props => {
    return (
        <Modal {...props} navigationTitle={props.title}>
            <div className={style.content}>
                <Collection
                    listLayout
                    items={props.steps}
                    itemType={props.kind}
                    component={HistoryItem} />
            </div>
        </Modal>
    );

};

export default HistoryModal;
