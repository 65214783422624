
const generate = (fetchData, profile) => {
    const notifications = [];
    const fetchRank = fetchData.groupRank || [];
    const defaultRank = fetchRank.length ? fetchRank[0] : null;
    const defaultCompetitor = fetchData.competitors && fetchData.competitors.length ? fetchData.competitors[0] : null;

    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const dateString = date.toString();

    // TODO: refactor the localstorage shit at some point, but basically notifications 
    // should only show once a day per type per device.

    if (defaultRank && defaultCompetitor && defaultRank.rank > 1) {
        const recommendation = defaultCompetitor.recommendedPoints;

        const notification = {
            type: 'competitors-behind',
            photo: defaultCompetitor.secondaryInfo.photo,
            title: `Don't let ${defaultCompetitor.firstName} ${defaultCompetitor.lastName} beat you!`,
            subtitle: `Right now, you're #${defaultRank.rank} in ${defaultRank.name}. You need ${recommendation} points to claim #1!`,
            shareData: {
                type: 'competitors-behind',
                user: profile._id,
                rank: defaultRank.rank,
                group: defaultRank._id,
                groupName: defaultRank.name,
                competitor: { 
                    _id: defaultCompetitor._id,
                    firstName: defaultCompetitor.firstName,
                    lastName: defaultCompetitor.lastName,
                    photo: defaultCompetitor.secondaryInfo.photo
                }
            }
        };

        const key = 'competitors_behind_' + dateString;
        if (!localStorage.getItem(key)) notifications.push(notification);
        localStorage.setItem(key, 'true');
    }

    if (defaultRank && defaultRank.rank === 1) {
        const notification = {
            type: 'competitors-reached',
            photo: profile.secondaryInfo.photo,
            title: `Amazing, you're in first place!`,
            subtitle: `Right now, you're #1 in ${defaultRank.name}. Keep up the good work, ${profile.firstName}!`,
            shareData: {
                type: 'competitors-reached',
                user: profile._id,
                rank: defaultRank.rank,
                group: defaultRank._id,
                groupName: defaultRank.name,
                competitor: null
            }
        };

        const key = 'competitors_reached_' + dateString;
        if (!localStorage.getItem(key)) notifications.push(notification);
        localStorage.setItem(key, 'true');
    }

    if (fetchData.addedBadges && fetchData.addedBadges.length) {
        for (const badge of fetchData.addedBadges) {
            const notification = {
                type: 'badge-earned',
                photo: badge.secondaryInfo.photo,
                title: badge.name,
                subtitle: `Great work, ${profile.firstName}! You earned a new badge.`,
                shareData: {
                    type: 'badge-earned',
                    user: profile._id,
                    badge: badge._id,
                    badgeName: badge.name,
                    badgePhoto: badge.secondaryInfo.photo
                }
            };
    
            notifications.push(notification);
        }
    }

    try {
        // console.log(fetchData)
        if (fetchData.addedRewardGroups && fetchData.addedRewardGroups.length) {
            for (const group of fetchData.addedRewardGroups) {
                const notification = {
                    type: 'reward-earned',
                    photo: group.secondaryInfo.thumbnail,
                    title: group.name,
                    subtitle: `Great work, ${profile.firstName}! You're eligible for a new reward.`,
                    shareData: {
                        type: 'reward-earned',
                        user: profile._id,
                        group: group._id,
                        groupName: group.name,
                        groupPhoto: group.secondaryInfo.thumbnail
                    }
                };
                // console.log(notification);

                notifications.push(notification);
            }
        }
    } catch (e) {console.log(e)}

    return notifications;
};

export default { generate };
