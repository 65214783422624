
import authStorage from './authStorage';

const authHeader = () => {
    const auth = authStorage.get();

    if (!auth || !auth.token) {
        const error = new Error('No auth token found');
        error.code = 'auth';
        throw error;
    }

    return { headers: { 'Authorization': auth.token } };
};

export default authHeader;
