import firebase from 'firebase'
import 'firebase/storage'

export const app = firebase.initializeApp({
    apiKey: "AIzaSyDRyv441ggjwxiUH5fBaOYv3VgBEdqFGzw",
    authDomain: "amp--v2.firebaseapp.com",
    databaseURL: "https://amp--v2.firebaseio.com",
    projectId: "amp--v2",
    storageBucket: "amp--v2.appspot.com",
    messagingSenderId: "968831356955",
    appId: "1:968831356955:web:b8a1fe118b408c9b0468be",
    measurementId: "G-S1CXWZDF6M"
});
