import { request } from './';

// TODO: yes there will be another kind of goal thats not personal

const fetchBadges = async () => {
    const result = await request('/badges/both/fetch', {});
    return result.data;
};

export default { fetchBadges };
