
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import Modal from '../Modal';
import Collection from '../Collection';

import GoalEditItem from '../GoalEditItem';
import AddNewFooter from '../AddNewFooter';

import GoalsAdd from '../GoalsAdd';
import api from '../../api';

const GoalsEdit = props => {

    const [goals, setGoals] = useState([]);
    const [showAdd, setShowAdd] = useState(false);

    const fetchGoals = async () => {
        const fetchedGoals = await api.goals.fetchPersonal();
        const sortedGoals = fetchedGoals.sort((a, b) => new Date(b.date) - new Date(a.date));
        setGoals(sortedGoals);
    };

    useEffect(() => { fetchGoals() }, []);

    return (
        <Modal {...props} navigationTitle="Edit Your Goals">
            <div className={style.content}>
                <Collection
                    listLayout
                    items={goals}
                    itemType={'goalEdit'}
                    component={GoalEditItem}
                    footerComponent={AddNewFooter}
                    onSelect={() => {}}
                    onSelectFooter={() => setShowAdd(true)} />
            </div>
            <GoalsAdd visible={showAdd} onDismiss={() => setShowAdd(false)} onSave={_ => fetchGoals()} />
        </Modal>
    );

};

export default GoalsEdit;
