
import React, { useRef, useEffect, useState } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import TitleBar from '../TitleBar';
import api from '../../api';
let firstScrollY = 0;

const GroupStartModal = props => {

    const [data, setData] = useState({ name:'' });
    const ref = useRef();

    const updateData = (field, e) => {
        const value = e.target.value;
        setData(data => ({ ...data, [field]: value }));
    };

    const scrollToTop  = () => {
        if (!ref || !ref.current) return;
        ref.current.scrollTop = 0;
    };
    const handleAddGroup = async () =>{
        try {
            await api.groups.addGroup(data);
            props.onFinish();
        } catch (e) {
            console.log(e)
            props.error(e);
        }
    }

    useEffect(() => { 
        if (props.visible) {
            firstScrollY = window.scrollY;
            scrollToTop();
        }

        if (!props.visible) {
            window.scrollTo(0, firstScrollY);
            setTimeout(() => scrollToTop(), 500);
        }
    }, [props.visible]);

    const modalClass = conditional('Modal', style, props);
    return (
        <div className={modalClass + ' background'} ref={ref}>
            <TitleBar title={props.navigationTitle} onBack={props.onFinish} />
            <div className={style.content}>
                {props.children}
                <input type='text' onChange={e => updateData('name', e)} placeholder="Group Name..." className={style.groupInput}/>
                <div type='button' placeholder="Sart Group" onClick={handleAddGroup} className={style.btnInput}>
                    <span>Start Group</span>
                </div>
            </div>
        </div>
    );

};

export default GroupStartModal;
