
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';
import GoalSelector from '../GoalSelector';
import GoalStepper from '../GoalStepper';
import api from '../../api';

const GoalsAdd = props => {

    const [visible, setVisible] = useState(null);
    const [goal, setGoal] = useSmoothState({
        type: 'points',
        time: 'daily',
        goal: 50
    }, 0.5);

    useEffect(() => {
        if (!props.visible) return;
        setVisible(props.visible);
    }, [props.visible]);

    useEffect(() => { loadDefaults() }, [goal.type, goal.time]);

    const confirm = async () => {
        await api.goals.addPersonal(goal);
        props.onSave(goal);
        dismiss();
    };

    const dismiss = () => {
        setGoal('initial');
        setVisible(false);
        props.onDismiss();
    };

    const selectType = type => setGoal(goal => ({ ...goal, type }));
    const selectTime = time => setGoal(goal => ({ ...goal, time }));

    const loadDefaults = () => {
        if (goal.time === 'daily') {
            if (goal.type === 'steps') setGoal(goal => ({ ...goal, goal: 5000 }));
            if (goal.type === 'points') setGoal(goal => ({ ...goal, goal: 50 }));
        }

        if (goal.time === 'weekly') {
            if (goal.type === 'steps') setGoal(goal => ({ ...goal, goal: 35000 }));
            if (goal.type === 'points') setGoal(goal => ({ ...goal, goal: 350 }));
        }

        if (goal.time === 'quarterly') {
            if (goal.type === 'steps') setGoal(goal => ({ ...goal, goal: 450000 }));
            if (goal.type === 'points') setGoal(goal => ({ ...goal, goal: 4500 }));
        }
    };

    const stepIncrementValue = () => {
        if (goal.time === 'weekly') {
            if (goal.type === 'steps') return 1750;
            if (goal.type === 'points') return 20;
        }

        if (goal.time === 'quarterly') {
            if (goal.type === 'steps') return 22500;
            if (goal.type === 'points') return 200;
        }

        if (goal.type === 'steps') return 250;
        if (goal.type === 'points') return 25;
        return 0;
    };

    const goalClass = conditional('GoalsAdd', style, { visible });

    return (
        <div className={goalClass} onClick={dismiss}>
            <div className={style.container} onClick={e => e.stopPropagation()}>
                <h3 className={style.title}>Add Goal</h3>

                <p className={style.description}>What do you want to improve?</p>
                <GoalSelector 
                    categories={['points', 'steps']} 
                    selected={goal.type}
                    onSelect={category => selectType(category)} />

                <p className={style.description}>Over what period of time?</p>
                <GoalSelector 
                    categories={['daily', 'weekly', 'quarterly']} 
                    selected={goal.time}
                    onSelect={category => selectTime(category)} />

                <p className={style.description}>What is your goal?</p>
                <GoalStepper
                    value={goal.goal}
                    incrementValue={stepIncrementValue()}
                    onIncrement={value => setGoal(goal => ({ ...goal, goal: goal.goal + value }))} />

                <div className={style.buttonContainer}>
                    <div className={style.done} onClick={() => confirm()}>Save Goal</div>
                </div>
            </div>
        </div>
    );

};

export default GoalsAdd;
