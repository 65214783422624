
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import api from '../../api';

const appStoreUrl = 'https://apps.apple.com/us/app/active-marion-project-2/id1533435589';
const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.mchdactivemarionproject.amp2'

const LinkPage = props => {

    const [link, setLink] = useState({});
    const fetchLink = async () => {
        const code = getCode();
        const link = await api.links.fetch(code);
        setLink(link);
    };

    useEffect(() => { fetchLink() }, []);

    const getCode = () => {
        const paths = props.path.split('/');
        return paths[paths.length - 1];
    };

    const download = () => {
        // TODO: track this with link._id when they get the app
        if(/webOS|iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
            // some code..
            console.log("IOS");
            window.open(appStoreUrl, '_blank');
           }
          if(/Android/i.test(navigator.userAgent)){
            // true for mobile device
            window.open(playStoreUrl, '_blank');
          }
    };

    const user = (link || {}).user || {};

    let shareData = {
        photo: '',
        title: '',
        subtitle: '',
        cta: ''
    };

    if (link.rank) {
        shareData = {
            photo: (user.secondaryInfo || {}).photo || '',
            title: `${user.firstName} is #${link.rank.rank}!`,
            subtitle: `Your friend ${user.firstName} ranked #${link.rank.rank} in a group called ${link.rank.name} on Active Marion Project. You can join too by downloading the app!`,
            cta: 'Get The App'
        };
    }

    if (link.goal) {
        const percent = Math.round(link.goal.percentage * 100);

        shareData = {
            photo: (user.secondaryInfo || {}).photo || '',
            title: `${user.firstName} reached ${link.goal.time} ${link.goal.type} goal by ${percent}%!`,
            subtitle: `Your friend ${user.firstName} set a goal of ${link.goal.goal} ${link.goal.time} ${link.goal.type} and got ${link.goal.progress}! You can reach your goals too by downloading the app!`,
            cta: 'Get The App'
        };
    }

    if (link.badge) {
        shareData = {
            photo: (user.secondaryInfo || {}).photo || '',
            title: `${user.firstName} earned the ${link.badge.name} badge!`,
            subtitle: `Your friend ${user.firstName} was just awarded the ${link.badge.name} badge! You can earn badges like this too by downloading the app!`,
            cta: 'Get The App'
        };
    }

    return (
        <div className={style.LinkPage}>
            <div className={style.container}>
                <div className={style.photo} style={{ backgroundImage: 'url(' + shareData.photo + ')' }} />
                <div className={style.title}>{shareData.title}</div>
                <div className={style.description}>{shareData.subtitle}</div>
                <div className={style.done} onClick={download}>{shareData.cta}</div>
            </div>
            <div className={style.watermark} />
        </div>
    );

};

export default LinkPage;
